import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Fab,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import MapMarker from "./MapMarker";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import WbSunnyTwoToneIcon from "@mui/icons-material/WbSunnyTwoTone";
import { formatArea } from "../../Calcul/CalculSheet";

function Question7_3({
  center,
  setCenter,
  next,
  previous,
  path,
  setPath,
  setChoice,
  choice,
  setChoiceQ6,
  area,
  isSelected,
  setQuestionToValue,
}) {
  const cardRef = React.useRef(null);

  useEffect(() => {
    if (isSelected) {
      cardRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [isSelected]);
  return (
    <Card
      ref={cardRef}
      className={isSelected ? "ae-card selected" : "ae-card"}
      variant="outlined"
      onClick={() => {
        setQuestionToValue(8);
      }}
    >
      <CardHeader title="Sélectionnez votre toit sur la carte" />
      <CardContent
        sx={{
          width: "100%",
        }}
      >
        <MapMarker
          path={path}
          setPath={setPath}
          center={center}
          setCenter={setCenter}
          setChoice={setChoice}
          setChoiceQ6={setChoiceQ6}
          choice={choice}
        />
        <Typography variant="body2" color="text.secondary">
          Repérez votre toit et centrez le sur la carte
        </Typography>

        {choice && (
          <>
            <WbSunnyTwoToneIcon color="primary" sx={{ marginTop: ".7em" }} />
            {choice.heure !== -1 ? (
              <Typography variant="button" display="inline-block" gutterBottom>
                Soit {choice.heure} heures d'ensoleillement par an
              </Typography>
            ) : (
              <Typography variant="button" display="inline-block" gutterBottom>
                vous avez quitter la carte de France
              </Typography>
            )}
          </>
        )}
        {choice.heure === -1 && (
          <Alert severity="error" style={{ margin: "1em 2em 0 0" }}>
            Notre solution d’autoconsommation ne s’adresse qu’aux habitants de
            la France.
          </Alert>
        )}

        {formatArea(area) < 15 && (
          <Alert severity="error">
            la surface de votre toit doit être supérieur à 15m²
          </Alert>
        )}
      </CardContent>
      <CardActions
        style={{
          display: "flex",
          justifyContent: "end",
          padding: "1em",
        }}
      >
        <Button
          style={{ textAlign: "start" }}
          variant="contained"
          // startIcon={<NavigateBeforeIcon color="white" />}
          disabled={formatArea(area) < 15 || choice.heure === -1}
          onClick={(e) => {
            e.stopPropagation();
            next(0);
          }}
        >
          Suivant
          <NavigateNextIcon color="black" />
        </Button>
      </CardActions>
    </Card>
  );
}

export default Question7_3;
