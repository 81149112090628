import { ListOfAddresses } from "../Components/Imports/ListeOfAddresses";
import {
  CONSOMMATION,
  PRIX_10_PV,
  PRIX_12_PV,
  PRIX_16_PV,
  PRIX_24_PV,
  PRIX_8_PV,
  PRIX_KWH,
  PRODUCTION_PAR_HEURE_ENSOLEILLEMENT_10_PV,
  PRODUCTION_PAR_HEURE_ENSOLEILLEMENT_12_PV,
  PRODUCTION_PAR_HEURE_ENSOLEILLEMENT_16_PV,
  PRODUCTION_PAR_HEURE_ENSOLEILLEMENT_24_PV,
  PRODUCTION_PAR_HEURE_ENSOLEILLEMENT_8_PV,
} from "./CONSOMMATION";

export const getFactureAnnuelle = (data) => {
  // ? Desctructuring the data Object
  const {
    nbrPerso,
    refrigerateur,
    congelateur,
    lave_vaisselle,
    lave_linge,
    seche_linge,
    piscine,
    pompe_a_chaleur_piscine,
    voiture_electrique,
    chauffage_gaz_fioul_bois,
    chauffage_electrique,
    chauffage_PAC_air_eau,
    chauffage_PAC_air_air,
    chauffe_eau_electrique,
    chauffe_eau_gaz_fioul,
    ballon_thermodynamique,
    area,
    surface,
  } = data;
  const factureAnnuelle = {
    refrigerateur: refrigerateur * CONSOMMATION.Refrigerateur,
    congelateur: congelateur * CONSOMMATION.Congelateur,
    lave_vaisselle: lave_vaisselle * CONSOMMATION.Lave_vaisselle,
    lave_linge: lave_linge * CONSOMMATION.Lave_linge,
    seche_linge: seche_linge * CONSOMMATION.Seche_linge,
    piscine: piscine * CONSOMMATION.Piscine,
    pompe_a_chaleur_piscine:
      pompe_a_chaleur_piscine * CONSOMMATION.Pompe_a_chaleur_piscine,
    voiture_electrique: voiture_electrique * CONSOMMATION.Voiture_electrique,
    chauffage_gaz_fioul_bois:
      chauffage_gaz_fioul_bois *
      CONSOMMATION.Chauffage_gaz_fioul_bois *
      surface,
    chauffage_electrique:
      chauffage_electrique * CONSOMMATION.Chauffage_electrique * surface,
    chauffage_PAC_air_eau:
      chauffage_PAC_air_eau * CONSOMMATION.Chauffage_PAC_air_eau * surface,
    chauffage_PAC_air_air:
      chauffage_PAC_air_air * CONSOMMATION.Chauffage_PAC_air_air * surface,
    chauffe_eau_electrique:
      chauffe_eau_electrique * CONSOMMATION.Chauffe_eau_electrique * nbrPerso,
    chauffe_eau_gaz_fioul:
      chauffe_eau_gaz_fioul * CONSOMMATION.Chauffe_eau_gaz_fioul * nbrPerso,
    ballon_thermodynamique:
      ballon_thermodynamique * CONSOMMATION.Ballon_thermodynamique * nbrPerso,
  };
  const total =
    Object.values(factureAnnuelle).reduce((a, b) => a + b, 0) * PRIX_KWH;
  const totalEnergie = Object.values(factureAnnuelle).reduce(
    (a, b) => a + b,
    0
  );
  return { factureAnnuelle, total, totalEnergie };
};

export const getNbrPvFromArea = (
  area,
  consommation,
  productionToutType,
  context
) => {
  const areaNumber = formatArea(area);
  const { store } = context;
  // get first element from productionToutType superior to consommation
  const minAreas = store.offres.map((item) => item.surfaceToit);
  let bestOption = productionToutType.findIndex(
    (item) => item.prod > consommation
  );
  if (bestOption === -1) {
    bestOption = productionToutType.length - 1;
  }

  while (bestOption > -1) {
    if (areaNumber < minAreas[bestOption]) {
      bestOption--;
    } else {
      break;
    }
  }

  if (bestOption > -1) {
    return {
      nbrPv: productionToutType[bestOption].nbPanneaux,
      prix: productionToutType[bestOption].prixTTC,
      production_kwh: productionToutType[bestOption].puissance,
      production_reel: productionToutType[bestOption].prod,
    };
  } else {
    return {
      nbrPv: 0,
      prix: 0,
      production_kwh: 0,
      production_reel: 0,
    };
  }
};

export const getCodePostal = (data) => {
  const { results } = data;
  const isInFrance = results.some((r) => {
    const { address_components } = r;
    return address_components.some((a) => {
      return a.long_name === "France";
    });
  });
  let codePostal = null;
  if (isInFrance) {
    const row = results.find((r) => {
      const { address_components } = r;
      return address_components.some((a) => {
        return a.types.includes("postal_code");
      });
    });
    if (row) {
      let element = row.address_components.find((r) => {
        return r.types.includes("postal_code");
      });
      codePostal = element.long_name;
    }
  } else codePostal = "notInFrance";
  return codePostal;
};

export const getEnsoleillement = (data) => {
  const codePostal = getCodePostal(data);
  if (codePostal === "notInFrance") return -1;
  const ensoleillement = ListOfAddresses.find((e) => {
    return e.codeRegion === codePostal.substring(0, 2);
  });

  return ensoleillement ? ensoleillement.heure : 1460;
};

export const formatArea = (area) => {
  if (typeof area === "string") return parseFloat(area.split(" ")[0]);
  return area;
};
