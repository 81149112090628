import { Alert, Button, Snackbar } from "@mui/material";
import React, { useState } from "react";
import Question1 from "../QuestionsCards/Question1";
import Question2 from "../QuestionsCards/Question2";
import Question3 from "../QuestionsCards/Question3";
import Question4 from "../QuestionsCards/Question4";
import Question5 from "../QuestionsCards/Question5";
import Question6 from "../QuestionsCards/Question6";
import Question7 from "../QuestionsCards/Question7";
import Question7_1 from "../QuestionsCards/Question7_1";
import Question7_3 from "../QuestionsCards/Question7_3";
import Question8 from "../QuestionsCards/Question8";
import Question9 from "../QuestionsCards/Question9";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useLoadScript } from "@react-google-maps/api";

const libraries = ["places"];

const ContainerLogement = ({
  questionCounter,
  choiceQ1,
  setChoiceQ1,
  choiceQ2,
  setChoiceQ2,
  choiceQ3,
  setChoiceQ3,
  choiceQ4,
  setChoiceQ4,
  choiceQ5,
  setChoiceQ5,
  choiceQ6,
  setChoiceQ6,
  choiceQ7,
  setChoiceQ7,
  choiceQ8,
  setChoiceQ8,
  choiceQ9,
  setChoiceQ9,
  isAddressFound,
  handleClickNext,
  handleClickPrevious,
  setIsAddressFound,
  center,
  setCenter,
  path,
  setPath,
  setQuestionToValue,
  handleChangeContainer,
  handleVisitConsommation,
}) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    libraries,
  });
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const [vertical, setvertical] = useState("top");
  const [horizontal, sethorizontal] = useState("center");

  const verifyPassToNextStep = () => {
    if (
      choiceQ1 !== "" &&
      choiceQ2 !== "" &&
      choiceQ3 !== "" &&
      choiceQ4 !== "" &&
      choiceQ5 !== "" &&
      choiceQ6 &&
      choiceQ6 !== 145 &&
      choiceQ6?.heure !== -1 &&
      choiceQ7 !== null &&
      choiceQ8 !== "" &&
      choiceQ9 !== "" &&
      choiceQ8 !== "Toit plat"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const verifyRoofIsSelected = () => {
    if (!choiceQ9 || choiceQ9 === "") {
      return true;
    } else {
      return false;
    }
  };

  const handleOpen = () => {
    setOpenSnackBar(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
  };

  return (
    <>
      <Question1
        isFirst={true}
        isLast={false}
        next={handleClickNext}
        previous={handleClickPrevious}
        choice={choiceQ1}
        setChoice={setChoiceQ1}
        isSelected={questionCounter === 1}
        setQuestionToValue={setQuestionToValue}
      />
      <Question2
        isFirst={false}
        isLast={false}
        next={handleClickNext}
        previous={handleClickPrevious}
        choice={choiceQ2}
        setChoice={setChoiceQ2}
        isSelected={questionCounter === 2}
        setQuestionToValue={setQuestionToValue}
      />
      <Question3
        isFirst={false}
        isLast={false}
        next={handleClickNext}
        previous={handleClickPrevious}
        choice={choiceQ3}
        setChoice={setChoiceQ3}
        isSelected={questionCounter === 3}
        setQuestionToValue={setQuestionToValue}
      />
      <Question4
        isFirst={false}
        isLast={false}
        next={handleClickNext}
        previous={handleClickPrevious}
        choice={choiceQ4}
        setChoice={setChoiceQ4}
        isSelected={questionCounter === 4}
        setQuestionToValue={setQuestionToValue}
      />
      <Question5
        isFirst={false}
        isLast={false}
        next={handleClickNext}
        previous={handleClickPrevious}
        choice={choiceQ5}
        setChoice={setChoiceQ5}
        isSelected={questionCounter === 5}
        setQuestionToValue={setQuestionToValue}
      />
      <Question6
        isFirst={false}
        isLast={false}
        next={handleClickNext}
        previous={handleClickPrevious}
        choice={choiceQ7}
        setChoice={setChoiceQ7}
        isSelected={questionCounter === 6}
        setQuestionToValue={setQuestionToValue}
      />
      {isAddressFound ? (
        isLoaded && (
          <Question7_1
            isFirst={false}
            isLast={false}
            next={handleClickNext}
            previous={handleClickPrevious}
            choice={choiceQ6}
            setChoice={setChoiceQ6}
            center={center}
            setCenter={setCenter}
            setIsAddressFound={setIsAddressFound}
            setQuestionToValue={setQuestionToValue}
            isSelected={questionCounter === 7}
          />
        )
      ) : (
        <Question7
          isFirst={false}
          isLast={false}
          next={handleClickNext}
          previous={handleClickPrevious}
          choice={choiceQ6}
          setChoice={setChoiceQ6}
          center={center}
          setCenter={setCenter}
          isSelected={questionCounter === 7}
          setQuestionToValue={setQuestionToValue}
        />
      )}
      {isAddressFound ? (
        // eslint-disable-next-line react/jsx-pascal-case
        isLoaded && (
          <Question7_3
            isFirst={false}
            isLast={false}
            next={handleClickNext}
            previous={handleClickPrevious}
            choice={choiceQ6}
            center={center}
            setCenter={setCenter}
            path={path}
            setPath={setPath}
            setChoice={setChoiceQ9}
            area={choiceQ9}
            setChoiceQ6={setChoiceQ6}
            isSelected={questionCounter === 8}
            setQuestionToValue={setQuestionToValue}
          />
        )
      ) : (
        <Question8
          isFirst={false}
          isLast={false}
          next={handleClickNext}
          previous={handleClickPrevious}
          choice={choiceQ8}
          setChoice={setChoiceQ8}
          order={8}
          isSelected={questionCounter === 8}
          setQuestionToValue={setQuestionToValue}
        />
      )}
      {isAddressFound ? (
        <Question8
          isFirst={false}
          isLast={false}
          next={handleClickNext}
          previous={handleClickPrevious}
          choice={choiceQ8}
          setChoice={setChoiceQ8}
          isSelected={questionCounter === 9}
          order={9}
          setQuestionToValue={setQuestionToValue}
        />
      ) : (
        <Question9
          isFirst={false}
          isLast={false}
          next={handleClickNext}
          previous={handleClickPrevious}
          choice={choiceQ9}
          setChoice={setChoiceQ9}
          isSelected={questionCounter === 9}
          setQuestionToValue={setQuestionToValue}
        />
      )}
      {verifyRoofIsSelected() && (
        <div style={{ padding: "1em" }}>
          <Alert severity="error">
            Verifier que vous avez bien Sélectionné votre toit sur la carte
          </Alert>
        </div>
      )}

      <Button
        style={{ textAlign: "end", margin: "2em" }}
        variant="contained"
        endIcon={<NavigateNextIcon color="black" />}
        disabled={!verifyPassToNextStep()}
        onClick={(e) => {
          e.stopPropagation();

          if (verifyPassToNextStep()) {
            setQuestionToValue(10);
            handleChangeContainer(2);
            handleVisitConsommation();
          } else setOpenSnackBar(true);
        }}
      >
        Valider et passer à l'étape suivante
      </Button>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={handleClose}
        vertical="top"
        horizontal="center"
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Vérifiez que vous avez bien répondu à toutes les questions
        </Alert>
      </Snackbar>
    </>
  );
};

export default ContainerLogement;
