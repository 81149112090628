import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  Input,
  InputLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useMemo } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import InputAdornment from "@mui/material/InputAdornment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import useOffre from "../../Calcul/Offre";
import { useEffect } from "react";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

function Question15({
  isFirst,
  isLast,
  next,
  previous,
  choice,
  setChoice,
  Store,
  isSelected,
  setQuestionToValue,
}) {
  const [selectedOffer, setSelectedOffer] = useState("Solaire");
  const offre = useOffre({ Store });
  useEffect(() => {
    const abort = new AbortController();
    return () => {
      abort.abort();
    };
  }, []);

  const rows = useMemo(
    () => [
      createData(
        "Estimation de la baisse de facture *",
        Math.round(offre.estimationBaisseFacture) + " %"
      ),
      createData("Prix TTC", offre.prixPanels + " €"),
      createData(
        "Retour sur investissement estimé",
        offre.retourSurInvestissement + " ans"
      ),
      createData("Nombre de panneaux", offre.nbrePaneaux),
      createData(
        "Puissance de la centrale solaire",
        offre.puissanceCentrale + " kWc"
      ),
      createData("Puissance d'un panneau", offre.puissancePaneaux + " Wc"),
      createData(
        "Production solaire par an",
        offre.productionPaneauxParAns + " kWh"
      ),
      createData("Type de panneaux", offre.panelType),
      createData("Type d'onduleur", offre.onduleurType),
      createData("Prime à l'autoconsommation solaire", offre.prime + " €"),
      createData("Aide maximum avec MaPrimeRénov’", "0 €"),
      createData("Prix TTC aides de l'état déduites", offre.prixFinal + " €"),
      createData("Dont éco-taxe", offre.dontTax + " €"),
    ],
    [offre]
  );
  const cardRef = React.useRef(null);

  useEffect(() => {
    if (isSelected) {
      cardRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [isSelected]);
  return (
    <Card
      sx={{ padding: "1rem" }}
      variant="outlined"
      ref={cardRef}
      className={isSelected ? "ae-card selected" : "ae-card"}
      onClick={() => setQuestionToValue(15)}
    >
      <CardHeader title="Voici l'offre recommandé:" />
      <CardContent
        sx={{
          width: "100%",
        }}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <caption>
              Estimation indicative et non contractuelle dépendant notamment des
              conditions d’ensoleillement réel et des choix et pratiques
              quotidiennes d’utilisation des équipements électriques dans le
              foyer.
            </caption>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell
                  align="center"
                  onClick={() => {
                    setSelectedOffer("Solaire");
                  }}
                  style={{
                    fontSize: "1.1rem",
                    fontWeight: "bold",
                    width: "15%",
                    borderBottom: selectedOffer === "Solaire" ? "none" : null,

                    backgroundColor:
                      selectedOffer === "Solaire" ? "#00012e" : null,
                    cursor:
                      selectedOffer !== "Solaire" ? "pointer" : "not-allowed",
                  }}
                >
                  Offre Solaire
                </TableCell>
                {/* <TableCell
                  align="center"
                  style={{
                    fontSize: "1.1rem",
                    fontWeight: "bold",
                    width: "15%",
                    borderBottom:
                      selectedOffer === "Toutes options" ? "none" : null,

                    backgroundColor:
                      selectedOffer === "Toutes options" ? "#00708f" : null,
                    cursor:
                      selectedOffer !== "Toutes options"
                        ? "pointer"
                        : "not-allowed",
                  }}
                  onClick={() => {
                    setSelectedOffer("Toutes options");
                  }}
                >
                  Offre Toutes options
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: "#ffffff",
                      borderBottom: selectedOffer === "Solaire" ? "none" : null,
                      backgroundColor:
                        selectedOffer === "Solaire" ? "#00012e" : null,
                      cursor:
                        selectedOffer !== "Solaire" ? "pointer" : "not-allowed",
                    }}
                    onClick={() => {
                      setSelectedOffer("Solaire");
                    }}
                  >
                    {row.calories}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <CardActions
        style={{
          display: "flex",
          justifyContent: "end",

          padding: "1em",
        }}
      >
        <Button
          style={{ textAlign: "start" }}
          variant="contained"
          disabled={isLast}
          onClick={(e) => {
            e.stopPropagation();
            setQuestionToValue(16);

            //next();
          }}
        >
          Suivant
          <NavigateNextIcon color="black" />
        </Button>
      </CardActions>
    </Card>
  );
}

export default Question15;
