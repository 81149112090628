import React from "react";
import { useMediaQuery } from "react-responsive";
import "./index.css";
function Footer() {
  const showMobile = useMediaQuery({ minWidth: 1290 });

  return (
    <footer
      className="footer"
      style={{
        display: "flex",
        justifyContent: "space-around",
        height: "50px",
        fontSize: showMobile ? "1em" : "0.6em",
      }}
    >
      <p>Simulateur Energie est un service de Infinity Energie Groupe</p>
      <p>
        Besoin d'autres informations ?{" "}
        <a href="https://www.infinity-energie-groupe.net/" style={{ color: "white" }}>
          {" "}
          Retournez sur le site de Infinity Energie Groupe®
        </a>
      </p>
    </footer>
  );
}

export default Footer;
