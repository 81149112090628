import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Fab,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import InputAdornment from "@mui/material/InputAdornment";
import { listeParrainage } from "../../Calcul/ListCodeParrainage";
import axios from "axios";
import { useState } from "react";

const BACKEN_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_BACKEND_URL
    : process.env.REACT_APP_BACKEND_URL_DEV;

function Question17({
  Store,
  isFirst,
  isLast,
  next,
  previous,
  choice,
  setChoice,
  isSelected,
  setQuestionToValue,
}) {
  const handleChangeInput = (event) => {
    setChoice({ ...choice, [event.target.name]: event.target.value });
  };

  const cardRef = React.useRef(null);
  const [arrayRegie, setArrayRegie] = useState([]);

  useEffect(() => {
    if (!Store.choiceQ6.codeRegion) {
      setChoice((old) => ({
        ...old,
        adresse: Store.choiceQ6.label,
        codePostal: Store.choiceQ6.postalCode,
        ville: Store.choiceQ6.city,
      }));
    }
  }, [Store.choiceQ6]);
  const fetchRegie = () => {
    axios.get(`${BACKEN_URL}/regies`).then((res) => {
      setArrayRegie(res.data);
    });
  };
  useEffect(() => {
    fetchRegie();
  }, []);
  useEffect(() => {
    if (isSelected) {
      cardRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [isSelected]);
  const initialChoice = {
    prenom: "",
    nom: "",
    telephone: "",
    email: "",
    adresse: "",
    adresse2: "",
    codePostal: "",
    ville: "",
    codeParrainage: "",
  };
  return (
    <Card
      sx={{ padding: "1rem" }}
      variant="outlined"
      ref={cardRef}
      className={isSelected ? "ae-card selected" : "ae-card"} 
      onClick={() => setQuestionToValue(18)}
    >
      <CardHeader title="Finalisons votre rapport personnalisé" />
      <CardContent
        sx={{
          width: "100%",
        }}
      >
        <Grid container spacing={2} style={{ padding: "1em", width: "100%" }}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel htmlFor="email">Email</InputLabel>
              <Input
                error={choice.email === ""}
                id="email"
                name="email"
                type="email"
                value={choice.email}
                onChange={handleChangeInput}
                helperText={choice.email === "" ? "Champs obligatoire" : ""}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel htmlFor="nom">Nom</InputLabel>
              <Input
                error={choice.nom === ""}
                id="nom"
                name="nom"
                type="text"
                value={choice.nom}
                onChange={handleChangeInput}
                helperText={choice.nom === "" ? "Champs obligatoire" : ""}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel htmlFor="prenom">Prénom</InputLabel>
              <Input
                error={choice.prenom === ""}
                id="prenom"
                name="prenom"
                type="text"
                value={choice.prenom}
                onChange={handleChangeInput}
                helperText={choice.prenom === "" ? "Champs obligatoire" : ""}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel htmlFor="email">Téléphone</InputLabel>
              <Input
                error={choice.telephone === ""}
                id="telephone"
                name="telephone"
                type="tel"
                value={choice.telephone}
                onChange={handleChangeInput}
                helperText={choice.telephone === "" ? "Champs obligatoire" : ""}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel htmlFor="adresse">Adresse</InputLabel>

              <Input
                error={choice.adresse === ""}
                id="adresse"
                name="adresse"
                type="text"
                value={choice.adresse}
                onChange={handleChangeInput}
                helperText={choice.adresse === "" ? "Champs obligatoire" : ""}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel htmlFor="adresse2">Adresse ( suite )</InputLabel>

              <Input
                id="adresse2"
                name="adresse2"
                type="text"
                value={choice.adresse2}
                onChange={handleChangeInput}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel htmlFor="codePostal">Code postal</InputLabel>
              <Input
                error={choice.codePostal === ""}
                id="codePostal"
                name="codePostal"
                type="text"
                value={choice.codePostal}
                onChange={handleChangeInput}
                helperText={
                  choice.codePostal === "" ? "Champs obligatoire" : ""
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel htmlFor="ville">Ville</InputLabel>
              <Input
                error={choice.ville === ""}
                id="ville"
                name="ville"
                type="text"
                value={choice.ville}
                onChange={handleChangeInput}
                helperText={choice.ville === "" ? "Champs obligatoire" : ""}
              />
            </FormControl>
          </Grid>
          {/*    <Grid item xs={12}>
            <FormControl variant="standard" fullWidth>
              <InputLabel htmlFor="code_parrainage" id="Code Parrainage">
                Code Parrainage
              </InputLabel>
              <Select
                //error={!choice.code_parrainage || choice.code_parrainage === ""}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={choice.code_parrainage}
                onChange={handleChangeInput}
                label="Code Parrainage"
                name="code_parrainage"
              >
                {arrayRegie.map((regie) => (
                  <MenuItem key={regie._id} value={regie.code}>
                    {regie.code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid> */}
        </Grid>
      </CardContent>
      <CardActions
        style={{
          display: "flex",
          justifyContent: "end",
          padding: "1em",
        }}
      >
        <Button
          style={{ textAlign: "start" }}
          variant="contained"
          disabled={
            choice.prenom === "" ||
            choice.nom === "" ||
            choice.email === "" ||
            choice.telephone === "" ||
            choice.adresse === "" ||
            choice.codePostal === "" ||
            choice.ville === ""
            // choice.code_parrainage === "" ||
            // !choice.code_parrainage
          }
          onClick={(e) => {
            e.stopPropagation();
            setQuestionToValue(19);

            //next();
          }}
        >
          Suivant
          <NavigateNextIcon color="black" />
        </Button>
      </CardActions>
    </Card>
  );
}

export default Question17;
