import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { GlobalContext } from "../../Context/GloablContext";
import useOffre, { useOffrePromo } from "../../Calcul/Offre";
import axios from "axios";

function PromoCard({ isSelected, setQuestionToValue, Store, setPromoOffre }) {
  const [isVerified, setIsVerified] = useState(false);
  const [selectedOffre, setSelectedOffre] = useState(null);
  const offreRecommended = useOffre({ Store });
  const cardRef = React.useRef(null);
  const context = useContext(GlobalContext);
  const [remise, setRemise] = useState(0);

  const offre = useOffrePromo({ Store, offre: selectedOffre, remise });
  useEffect(() => {
    if (selectedOffre) {
      setPromoOffre(offre);
    } else {
      setPromoOffre(offreRecommended);
    }
  }, [selectedOffre]);
  useEffect(() => {
    if (isSelected) {
      if (context.code === -1) {
        setQuestionToValue(18);
      }
      cardRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    } else {
      if (selectedOffre) {
        setPromoOffre(offre);
      } else {
        setPromoOffre(offreRecommended);
      }
    }
  }, [isSelected]);
  return (
    <Card
      sx={{ padding: "1rem", display: context.code === -1 ? "none" : "block" }}
      variant="outlined"
      ref={cardRef}
      className={isSelected ? "ae-card selected" : "ae-card"}
      onClick={() => setQuestionToValue(17)}
    >
      <CardHeader title="Espace régie" />
      <CardContent
        sx={{
          width: "100%",
        }}
      >
        {isVerified ? (
          <VerfiedComponent
            selectedOffre={selectedOffre}
            setSelectedOffre={setSelectedOffre}
            offres={context.store.offres}
            offreRecommended={offreRecommended}
            setRemise={setRemise}
            remise={remise}
          />
        ) : (
          <NonVerifiedComponent setIsVerified={setIsVerified} codeParrainage={context.code} />
        )}
      </CardContent>
      <CardActions 
        style={{
          display: "flex",
          justifyContent: "end",
          padding: "1em",
        }}
      >
        <Button
          style={{ textAlign: "start" }}
          variant="contained"
          onClick={(e) => {
            e.stopPropagation();
            setQuestionToValue(18);

            //next();
          }}
        >
          Suivant
          <NavigateNextIcon color="white" />
        </Button>
      </CardActions>
    </Card>
  );
}

export default PromoCard;

const VerfiedComponent = ({
  selectedOffre,
  setSelectedOffre,
  offres,
  offreRecommended,
  setRemise,
  remise,
}) => {
  const [percent, setPercent] = useState(0);
  return (
    <Stack
      spacing={4}
      sx={{
        padding: "1rem",
        paddingRight: "3rem",
      }}
    >
      <FormControl variant="standard">
        <InputLabel htmlFor="offre" id="offre">
          Offres TTC
        </InputLabel>{" "}
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedOffre}
          onChange={(e) => {
            setSelectedOffre(e.target.value);
          }}
          label="Offres"
          name="offre"
        >
          {offres.map((offre) => (
            <MenuItem key={offre._id} value={offre}>
              {offre.nbPanneaux} panneaux - {offre.prixTTC}€{" "}
              {offre.nbPanneaux === offreRecommended.nbrePaneaux
                ? "- Recommandée"
                : ""}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="standard">
        <InputLabel htmlFor="percent">Pourcentage</InputLabel>
        <Input
          id="percent"
          name="percent"
          type="number"
          value={percent}
          disabled={!selectedOffre}
          onChange={(e) => {
            setRemise((selectedOffre.prixTTC * e.target.value) / 100);
            setPercent(e.target.value);
          }}
        />
      </FormControl>
      <FormControl variant="standard">
        <InputLabel htmlFor="valeur">Remise TTC</InputLabel>
        <Input
          id="percent"
          name="percent"
          type="number"
          value={remise}
          disabled={!selectedOffre}
          onChange={(e) => {
            setRemise(e.target.value);
            setPercent(
              Math.round((e.target.value * 100) / selectedOffre.prixTTC)
            );
          }}
        />
      </FormControl>
    </Stack>
  );
};
const BACKEN_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_BACKEND_URL
    : process.env.REACT_APP_BACKEND_URL_DEV;
const NonVerifiedComponent = ({ setIsVerified,codeParrainage }) => {
  const [codePromo,setCodePromo]=useState("")
  const [error,setError]=useState(false)
  const verify=async()=>{
    try{
    await axios.post(`${BACKEN_URL}/api/regie/checkCodePromo`,{
      codePromo,
      codeParrainage
    })
    setIsVerified(true)
    }catch(err){
      setIsVerified(false)
      setError(true)
    }
  }
  return (
    <>
    <div style={{display:'flex',alignItems:'center'}}>
      <TextField
        id="codePromo"
        label="Code promo"
        size="small"
        
        value={codePromo}
        onChange={(e)=>setCodePromo(e.target.value)}
        sx={{marginRight:'1rem',width:'300px'}}
      />

      <Button
        variant="contained"
        onClick={verify}
        color="warning"
      >
        Vérifier
      </Button>
      
    </div>
    <Snackbar
    open={error}
    autoHideDuration={6000}
    onClose={() => setError(false)}
    vertical="top"
    horizontal="center"
    anchorOrigin={{ vertical:"top", horizontal:'center' }}
  >
    <Alert onClose={() => setError(false)} severity="error" sx={{ width: "100%" }}>
      Code promo invalide
    </Alert>
  </Snackbar>
  </>
  );
};
