import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomButton from "../CustomInputs/CustomButton";
import imgGrand from "../../assets/QuestionImages/q9_m3.png";
import imgMoyenne from "../../assets/QuestionImages/q9_m2.png";
import imgPetite from "../../assets/QuestionImages/q9_m1.png";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";

function Question9({
  isFirst,
  isLast,
  next,
  previous,
  choice,
  setChoice,
  setQuestionToValue,
  isSelected,
}) {
  const [activeBtn1, setActiveBtn1] = useState(false);
  const [activeBtn2, setActiveBtn2] = useState(false);
  const [activeBtn3, setActiveBtn3] = useState(false);

  useEffect(() => {
    let mount = true;
    if (choice === "Petite 15-20m²" && mount) {
      setActiveBtn1(true);
      setActiveBtn2(false);
    } else if (choice === "Moyenne 20-40m²" && mount) {
      setActiveBtn1(false);
      setActiveBtn2(true);
    } else if (choice === "Grande > 40m²" && mount) {
      setActiveBtn1(false);
      setActiveBtn2(false);
      setActiveBtn3(true);
    } else {
      setActiveBtn1(false);
      setActiveBtn2(false);
      setActiveBtn3(false);
    }
    return () => {
      mount = false;
    };
  }, [choice]);

  const handleClickBtn1 = () => {
    if (activeBtn1) {
      setActiveBtn1(false);
      setChoice("");
    } else {
      setActiveBtn1(true);
      setChoice("Petite 15-20m²");
    }
    setActiveBtn2(false);
    setActiveBtn3(false);
  };
  const handleClickBtn2 = () => {
    if (activeBtn2) {
      setActiveBtn2(false);
      setChoice("");
    } else {
      setActiveBtn2(true);
      setChoice("Moyenne 20-40m²");
    }
    setActiveBtn1(false);
    setActiveBtn3(false);
  };
  const handleClickBtn3 = () => {
    if (activeBtn3) {
      setActiveBtn3(false);
      setChoice("");
    } else {
      setActiveBtn3(true);
      setChoice("Grande > 40m²");
    }
    setActiveBtn1(false);
    setActiveBtn2(false);
  };
  const cardRef = React.useRef(null);

  useEffect(() => {
    if (isSelected) {
      cardRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [isSelected]);
  return (
    <Card
      ref={cardRef}
      className={isSelected ? "ae-card selected" : "ae-card"}
      variant="outlined"
      onClick={() => setQuestionToValue(9)}
    >
      <CardHeader
        title="Quelle est la taille de votre toiture ?"
        // subheader="La taille de votre toit conditionne le nombre de panneaux que l´on peut installer."
      />
      <CardContent
        style={{
          width: "100%",

          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          padding: "1.7em 0 1.7em 2.5em",
        }}
      >
        <CustomButton
          img={imgPetite}
          active={activeBtn1}
          onClick={handleClickBtn1}
          buttonText="Petite 15-20m²"
        />
        <CustomButton
          img={imgMoyenne}
          active={activeBtn2}
          onClick={handleClickBtn2}
          buttonText="Moyenne 20-40m²"
        />
        <CustomButton
          img={imgGrand}
          active={activeBtn3}
          onClick={handleClickBtn3}
          buttonText="Grande > 40m²"
        />
      </CardContent>
      {/* <CardActions
        style={{
          display: "flex",
          justifyContent: "end",
          marginTop: "1.5em",
          padding: "1em",
        }}
      >
        <Button
          style={{ textAlign: "end" }}
          variant="contained"
          endIcon={<NavigateNextIcon color="white" />}
          disabled={(!activeBtn1 && !activeBtn2 && choice === "") || isLast}
          onClick={(e) => {
            e.stopPropagation();
            next();
          }}
        >
          Valider et passer à l'étape suivante
        </Button>
      </CardActions> */}
    </Card>
  );
}

export default Question9;
