import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  Input,
  InputLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import InputAdornment from "@mui/material/InputAdornment";
import { useState } from "react";
import { getFactureAnnuelle } from "../../Calcul/CalculSheet";

function Question14({
  isFirst,
  isLast,
  next,
  previous,
  choice,
  setChoice,
  personne,
  electroMenage,
  chauffage,
  chauffeau,
  area,
  isSelected,
  setQuestionToValue,
  surface,
}) {
  const [factureAnnuelle, setFactureAnnuelle] = useState(0);
  const [consommationAnnuelle, setConsommationAnnuelle] = useState(0);
  const handleChnage1 = (event) => {
    setChoice({ ...choice, estimation: !choice.estimation });
  };
  const handleChnage2 = (event) => {
    setChoice({
      ...choice,
      factureAnnuelle: {
        enabled: !choice.factureAnnuelle.enabled,
        value: "",
      },
    });
  };

  useEffect(() => {
    const abortController = new AbortController();
    let areaNumber = area;
    switch (area) {
      case "Grande > 40m²":
        areaNumber = 50;
        break;
      case "Petite 15-20m²":
        areaNumber = 20;
        break;
      case "Moyenne 20-40m²":
        areaNumber = 40;
        break;
      case area.includes("m²"):
        areaNumber = area.split("m²")[0];
        break;
      default:
        areaNumber = area.split("m²")[0];
        break;
    }

    const result = getFactureAnnuelle({
      surface: surface,
      nbrPerso: personne,
      refrigerateur: electroMenage.Refrigerateur,
      congelateur: electroMenage.Congelateur,
      lave_linge: electroMenage.LaveLinge,
      lave_vaisselle: electroMenage.LaveVaisselle,
      seche_linge: electroMenage.SecheLinges,
      piscine: electroMenage.Piscine,
      pompe_a_chaleur_piscine: electroMenage.PompePiscine,
      voiture_electrique: electroMenage.voitureElectrique,
      chauffage_gaz_fioul_bois: chauffage.ChauffageGaz ? 1 : 0,
      chauffage_electrique: chauffage.ChauffageElectrique ? 1 : 0,
      chauffage_PAC_air_eau: chauffage.Pompe ? 1 : 0,
      chauffage_PAC_air_air: chauffage.Climatisation ? 1 : 0,
      chauffe_eau_electrique: chauffeau.ChauffeEau,
      chauffe_eau_gaz_fioul: chauffeau.ChauffageGaz ? 1 : 0,
      area: areaNumber,
      ballon_thermodynamique: chauffeau.Ballon ? 1 : 0,
    });

    setFactureAnnuelle(result.total);
    setConsommationAnnuelle(result.totalEnergie);
    return () => {
      abortController.abort();
    };
  }, [electroMenage, chauffeau, chauffage, personne, area]);

  const handleChangeInput = (event) => {
    setChoice({
      ...choice,
      facture: event.target.value,
      consommation: consommationAnnuelle,
    });
  };
  const handleChange = (event) => {
    if (event.target.value === "estimation") {
      console.log("Consommation annuelle", consommationAnnuelle);
      setChoice({
        ...choice,
        selected: event.target.value,
        facture: Math.round(factureAnnuelle),
        consommation: consommationAnnuelle,
      });
    } else {
      setChoice({ ...choice, selected: event.target.value });
    }
  };
  const cardRef = React.useRef(null);

  useEffect(() => {
    if (isSelected) {
      cardRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [isSelected]);
  return (
    <Card
      sx={{ padding: "1rem" }}
      variant="outlined"
      ref={cardRef}
      className={isSelected ? "ae-card selected" : "ae-card"}
      onClick={() => setQuestionToValue(14)}
    >
      <CardHeader
        title="Quelle est votre facture d´électricité ?"
        // subheader="Si vous ne connaissez pas le montant de votre facture, ce n´est pas grave. Nous l´estimons avec les questions précédentes.."
      />
      <CardContent
        sx={{
          width: "100%",
        }}
      >
        <FormControl component="fieldset" variant="standard">
          <RadioGroup
            style={{ display: "flex" }}
            value={choice.selected}
            onChange={handleChange}
          >
            <FormControlLabel
              value="facture"
              control={<Radio />}
              label={
                <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                  <InputLabel htmlFor="standard-adornment-amount">
                    Montant de votre facture annuelle
                  </InputLabel>
                  <Input
                    id="standard-adornment-amount"
                    onChange={handleChangeInput}
                    value={choice.facture}
                    readOnly={choice.selected !== "facture"}
                    startAdornment={
                      <InputAdornment
                        onChange={handleChangeInput}
                        value={choice.facture}
                        position="start"
                        readOnly={choice.selected !== "facture"}
                      >
                        €
                      </InputAdornment>
                    }
                  />
                </FormControl>
              }
              labelPlacement="end"
            />
            <FormControlLabel
              value="estimation"
              control={<Radio />}
              label={
                <Typography variant="body2">
                  Je n'ai pas ma facture et j'utilise l'estimation réalisée par
                  le simulateur à la suite de la saisie du détail de mes
                  équipements électriques : Le montant est :{" "}
                  {isNaN(Math.round(factureAnnuelle))
                    ? 0
                    : Math.round(factureAnnuelle)}{" "}
                  € TTC par an.
                </Typography>
              }
              labelPlacement="end"
            />
          </RadioGroup>
        </FormControl>
      </CardContent>
      <CardActions
        style={{
          display: "flex",
          justifyContent: "end",

          padding: "1em",
        }}
      ></CardActions>
    </Card>
  );
}

export default Question14;
