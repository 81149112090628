import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomButton from "../CustomInputs/CustomButton";
import imgToi1 from "../../assets/QuestionImages/q8_m1.png";
import imgToi2 from "../../assets/QuestionImages/q8_m2.png";
import imgToi3 from "../../assets/QuestionImages/q8_m3.png";
import imgToi4 from "../../assets/QuestionImages/q8_m4.png";
import imgToi5 from "../../assets/QuestionImages/q8_m5.png";
import imgToi6 from "../../assets/QuestionImages/q8_m6.png";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

function Question8({
  isFirst,
  isLast,
  next,
  previous,
  choice,
  setChoice,
  setQuestionToValue,
  order,
  isSelected,
}) {

 const activeBtn1=choice==="Sud";
 const activeBtn2=choice==="Sud-Est / Sud-Ouest";
  const activeBtn3=choice==="Est / Ouest";
  const activeBtn4=choice==="Trés ombragé ou orienté Nord";
  const activeBtn5=choice==="Toit plat";
  const activeBtn6=choice==="Je ne sais pas";

 

  const handleClickBtn1 = () => {
    if (activeBtn1) {
      setChoice("");
    } else {
      setChoice("Sud");
    }
    
  };
  const handleClickBtn2 = () => {
    if (activeBtn2) {
      setChoice("");
    } else {
      setChoice("Sud-Est / Sud-Ouest");
    }
   
  };
  const handleClickBtn3 = () => {
    if (activeBtn3) {
      setChoice("");
    } else {
      setChoice("Est / Ouest");
    }

  };
  const handleClickBtn4 = () => {
    if (activeBtn4) {
      setChoice("");
    } else {
      setChoice("Trés ombragé ou orienté Nord");
    }

  };
  const handleClickBtn5 = () => {
    if (activeBtn5) {
      setChoice("");
    } else {
      setChoice("Toit plat");
    }

  };
  const handleClickBtn6 = () => {
    if (activeBtn6) {
      setChoice("");
    } else {
      setChoice("Je ne sais pas");
    }
   
  };
  const cardRef = React.useRef(null);

  useEffect(() => {
    if (isSelected) {
      cardRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [isSelected]);
  return (
    <Card
      ref={cardRef}
      className={isSelected ? "ae-card selected" : "ae-card"}
      variant="outlined"
      onClick={() => setQuestionToValue(order)}
    >
      <CardHeader title="Quelle est l´orientation de votre toiture ?" />
      <CardContent
        style={{
          width: "100%",

          display: "flex",
          alignItems: "start",
          justifyContent: "start",
          padding: ".7em 0 .7em .7em",
        }}
      >
        <CustomButton
          img={imgToi1}
          active={activeBtn1}
          onClick={handleClickBtn1}
          buttonText="Sud"
        />
        <CustomButton
          img={imgToi2}
          active={activeBtn2}
          onClick={handleClickBtn2}
          buttonText="Sud-Est / Sud-Ouest"
        />
        <CustomButton
          img={imgToi3}
          active={activeBtn3}
          onClick={handleClickBtn3}
          buttonText="Est / Ouest"
        />
        {/** TODO : disable the next btn */}
        <CustomButton
          img={imgToi4}
          active={activeBtn4}
          onClick={handleClickBtn4}
          buttonText="Trés ombragé ou orienté Nord"
        />
        {/** TODO : disable the next btn */}
        <CustomButton
          img={imgToi5}
          active={activeBtn5}
          onClick={handleClickBtn5}
          buttonText="Toit plat"
        />
        <CustomButton
          img={imgToi6}
          active={activeBtn6}
          onClick={handleClickBtn6}
          buttonText="Je ne sais pas"
        />
      </CardContent>
      <CardActions
        style={{
          display: "flex",
          justifyContent: "start",
          padding: "1em",
        }}
      >
        {choice === "Toit plat" && (
          <Alert severity="error">
            Notre solution d’autoconsommation ne s’adresse pas aux toits plats
          </Alert>
        )}
      </CardActions>
    </Card>
  );
}

export default Question8;
