import { Alert, Button, Snackbar } from "@mui/material";
import React, { useState } from "react";
import Question10 from "../QuestionsCards/Question10";
import Question11 from "../QuestionsCards/Question11";
import Question12 from "../QuestionsCards/Question12";
import Question13 from "../QuestionsCards/Question13";
import Question14 from "../QuestionsCards/Question14";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

function ContainerConsommation({
  handleClickNext,
  handleClickPrevious,
  surface,
  choiceQ10,
  setChoiceQ10,
  choiceQ11,
  setChoiceQ11,
  choiceQ12,
  setChoiceQ12,
  choiceQ13,
  setChoiceQ13,
  choiceQ14,
  setChoiceQ14,
  choiceQ7,
  choiceQ9,
  questionCounter,
  setQuestionToValue,
  handleChangeContainer,
  handleVisitProjet,
}) {
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const [vertical, setvertical] = useState("top");
  const [horizontal, sethorizontal] = useState("center");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
  };

  const verifyPassToNextStep = () => {
    if (
      choiceQ10 !== "" &&
      JSON.stringify(choiceQ11) !==
        JSON.stringify({
          ChauffageElectrique: false,
          Climatisation: false,
          Pompe: false,
          ChauffageGaz: false,
        }) &&
      JSON.stringify(choiceQ12) !==
        JSON.stringify({
          Ballon: false,
          ChauffeEau: 0,
          Autre: false,
          ChauffageGaz: false,
        }) &&
      JSON.stringify(choiceQ13) !==
        JSON.stringify({
          Refrigerateur: 0,
          Congelateur: 0,
          LaveVaisselle: 0,
          LaveLinge: 0,
          SecheLinges: 0,
          Piscine: 0,
          PompePiscine: 0,
          voitureElectrique: 0,
        }) &&
      JSON.stringify(choiceQ14) !==
        JSON.stringify({
          selected: "facture",
          facture: "",
          consommation: "",
        })
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <>
      <Question10
        isFirst={false}
        isLast={false}
        next={handleClickNext}
        previous={handleClickPrevious}
        choice={choiceQ10}
        setChoice={setChoiceQ10}
        isSelected={questionCounter === 10}
        setQuestionToValue={setQuestionToValue}
      />

      <Question11
        isFirst={false}
        isLast={false}
        next={handleClickNext}
        previous={handleClickPrevious}
        choice={choiceQ11}
        setChoice={setChoiceQ11}
        isSelected={questionCounter === 11}
        setQuestionToValue={setQuestionToValue}
      />

      <Question12
        isFirst={false}
        isLast={false}
        next={handleClickNext}
        previous={handleClickPrevious}
        choice={choiceQ12}
        setChoice={setChoiceQ12}
        isSelected={questionCounter === 12}
        setQuestionToValue={setQuestionToValue}
      />

      <Question13
        isFirst={false}
        isLast={false}
        next={handleClickNext}
        previous={handleClickPrevious}
        choice={choiceQ13}
        setChoice={setChoiceQ13}
        isSelected={questionCounter === 13}
        setQuestionToValue={setQuestionToValue}
      />

      <Question14
        isFirst={false}
        isLast={false}
        next={handleClickNext}
        previous={handleClickPrevious}
        choice={choiceQ14}
        setChoice={setChoiceQ14}
        personne={choiceQ7}
        electroMenage={choiceQ13}
        chauffage={choiceQ11}
        chauffeau={choiceQ12}
        area={choiceQ9}
        isSelected={questionCounter === 14}
        setQuestionToValue={setQuestionToValue}
        surface={surface}
      />

      <Button
        style={{ textAlign: "end", margin: "2em" }}
        variant="contained"
        endIcon={<NavigateNextIcon color="black" />}
        disabled={!verifyPassToNextStep()}
        onClick={(e) => {
          e.stopPropagation();
          if (verifyPassToNextStep()) {
            setQuestionToValue(15);
            handleChangeContainer(3);
            handleVisitProjet();
          } else setOpenSnackBar(true);
        }}
      >
        Valider et passer à l'étape suivante
      </Button>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={handleClose}
        vertical="top"
        horizontal="center"
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Vérifiez que vous avez bien répondu à toutes les questions
        </Alert>
      </Snackbar>
    </>
  );
}

export default ContainerConsommation;
