import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from "@mui/material";
import React, { useEffect } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import AutoCompleteMaps from "./AutoCompleteMaps";
import { useState } from "react";

function Question7_1({
  center,
  setCenter,
  next,
  previous,
  setIsAddressFound,
  setChoice,
  setQuestionToValue,
  isSelected,
}) {
  const cardRef = React.useRef(null);

  useEffect(() => {
    if (isSelected) {
      cardRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [isSelected]);
  const [isInFrance, setisInFrance] = useState(-1);
  return (
    <Card
      ref={cardRef}
      className={isSelected ? "ae-card selected" : "ae-card"}
      variant="outlined"
      onClick={() => {
        setQuestionToValue(7);
      }}
    >
      <CardHeader title="Quelle est votre adresse ?" />
      <CardContent
        sx={{
          width: "100%",
        }}
      >
        <AutoCompleteMaps
          center={center}
          setCenter={setCenter}
          setChoice={setChoice}
          isInFrance={isInFrance}
          setisInFrance={setisInFrance}
        />
        {isInFrance !== 1 && isInFrance !== -1 && (
          <Alert severity="error" style={{ margin: "1em 2em 0 0" }}>
            Notre solution d’autoconsommation ne s’adresse qu’aux habitants de
            la France.
          </Alert>
        )}
        {/*  <div
          style={{
            display: "flex",
            justifyContent: "end",
            padding: "1em",
            margin: "1em 1em 0 0",
          }}
        ></div> */}
      </CardContent>
      <CardActions
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1em",
        }}
      >
        <Button
          variant="contained"
          onClick={() => {
            setChoice("");
            setIsAddressFound(false);
          }}
        >
          Je ne trouve pas mon adresse
        </Button>
        <Button
          style={{ textAlign: "end" }}
          variant="contained"
          endIcon={<NavigateNextIcon color="black" />}
          /*  disabled={
            (!activeBtn1 && !activeBtn2 && choice === "") ||
            isLast ||
            activeBtn2
          } */
          disabled={isInFrance !== 1}
          onClick={(e) => {
            e.stopPropagation();
            next(0);
          }}
        >
          Suivant
        </Button>
      </CardActions>
    </Card>
  );
}

export default Question7_1;
