import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@mui/material";
import React, { useEffect } from "react";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

function Question11({
  isFirst,
  isLast,
  next,
  previous,
  choice,
  setChoice,
  isSelected,
  setQuestionToValue,
}) {
  const handleChange = (event) => {
    setChoice({
      ...choice,
      [event.target.name]: event.target.checked,
    });
  };
  const cardRef = React.useRef(null);

  useEffect(() => {
    if (isSelected) {
      cardRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [isSelected]);
  return (
    <Card
      sx={{ padding: "1rem" }}
      variant="outlined"
      ref={cardRef}
      className={isSelected ? "ae-card selected" : "ae-card"}
      onClick={() => setQuestionToValue(11)}
    >
      <CardHeader
        title="Quel type de chauffage possédez vous ?"
        // subheader="Votre mode de chauffage a un impact important sur votre facture et les économies que vous pourriez réaliser."
      />
      <CardContent
        style={{
          width: "100%",

          padding: "0 0 0 2.5em",
        }}
      >
        <div
          style={{
            display: "block",
            textAlign: "start",
            width: "40%",
            /* alignItems: "center",
              justifyContent: "start", */
          }}
        >
          <FormControl component="fieldset" variant="standard">
            {/*  <FormLabel component="legend">Assign responsibility</FormLabel> */}
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={choice?.ChauffageElectrique}
                    onChange={handleChange}
                    name="ChauffageElectrique"
                  />
                }
                label="Chauffage électrique"
                style={{ margin: ".3em" }}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={choice?.Climatisation}
                    onChange={handleChange}
                    name="Climatisation"
                  />
                }
                label="Climatisation (PAC Air/Air)"
                style={{ margin: ".3em" }}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={choice?.Pompe}
                    onChange={handleChange}
                    name="Pompe"
                  />
                }
                label="Pompe à chaleur Air/Eau"
                style={{ margin: ".3em" }}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={choice?.ChauffageGaz}
                    onChange={handleChange}
                    name="ChauffageGaz"
                  />
                }
                label="Chauffage gaz, fioul ou bois"
                style={{ margin: ".3em" }}
              />
            </FormGroup>
          </FormControl>
        </div>
      </CardContent>
      <CardActions
        style={{
          display: "flex",
          justifyContent: "end",

          padding: "1em",
        }}
      >
        <Button
          style={{ textAlign: "start" }}
          variant="contained"
          disabled={isLast}
          onClick={(e) => {
            e.stopPropagation();
            next(0);
          }}
        >
          Suivant
          <NavigateNextIcon color="black" />
        </Button>
      </CardActions>
    </Card>
  );
}

export default Question11;
