
export const ListOfAddresses = [
  { label: "Bouches du rhone", codeRegion: "13",heure:1690},
  { label: "Corse-du-Sud", codeRegion: "20" ,heure:1690},
  { label: "Var",  codeRegion: "83", heure:1660}, 
  { label: "Alpes-Maritimes",  codeRegion: "06" ,heure:1590},
  { label: "Gard", codeRegion: "30", heure:1580},
  { label: "Hérault", codeRegion: "34",heure:1570},
  { label: "Drôme", codeRegion: "26", heure:1470},
  { label: "Hautes-Alpes",  codeRegion: "05" ,heure:1540},
  { label: "Alpes-de-Haute-Provence",  codeRegion: "04",heure:1540 },
  { label: "Pyrénées-Orientales", codeRegion: "66", heure:1580 },
  { label: "Vaucluse", codeRegion: "84", heure:1630 },
  { label: "Charente-Maritime", codeRegion: "17", heure:1430 },
  { label: "Aude", codeRegion: "11", heure:1470 },
  { label: "Cantal", codeRegion: "15", heure:1350 },
  { label: "Lozère", codeRegion: "48", heure:1380 },
  { label: "Charente", codeRegion: "16", heure:1340 },
  { label: "Loire-Atlantique", codeRegion: "44", heure:1310 },
  { label: "Dordogne", codeRegion: "24",  heure:1360},
  { label: "Haute-Corse", codeRegion: "20", heure:1530 },
  { label: "Corrèze", codeRegion: "19", heure:1320 },
  { label: "Lot", codeRegion: "46", heure:1390 },
  { label: "Ardèche", codeRegion: "07", heure:1450 },
  { label: "Vienne", codeRegion: "86", heure:1310 },
  { label: "Tarn", codeRegion: "81", heure:1410 },
  { label: "Gironde", codeRegion: "33", heure:1400 },
  { label: "Indre-et-Loire", codeRegion: "37", heure:1280 },
  { label: "Maine-et-Loire", codeRegion: "49", heure:1280 },
  { label: "Lot-et-Garonne", codeRegion: "47", heure:1400 },
  { label: "Deux-Sèvres", codeRegion: "79", heure:1330 },
  { label: "Loir-et-Cher", codeRegion: "41", heure:1250 },
  { label: "Haute-Marne", codeRegion: "52", heure:1170 },
  { label: "Morbihan", codeRegion: "56", heure:1290 },
  { label: "Rhône", codeRegion: "69", heure:1340 },
  { label: "Vendée", codeRegion: "85", heure:1340 },
  { label: "Ain", codeRegion: "01", heure:1320 },
  { label: "Cher", codeRegion: "18", heure:1250 },
  { label: "Tarn-et-Garonne", codeRegion: "82", heure:1420 },
  { label: "Isère", codeRegion: "38", heure:1390 },
  { label: "Aveyron", codeRegion: "12", heure:1410 },
  { label: "Côte-d'Or", codeRegion: "21", heure:1220 },
  { label: "Ariège", codeRegion: "09", heure:1350 },
  { label: "Loiret", codeRegion: "45", heure:1220 },
  { label: "Indre", codeRegion: "36", heure:1270 },
  { label: "Haute-Vienne", codeRegion: "87", heure:1280 },
  { label: "Saône-et-Loire", codeRegion: "71", heure:1290 },
  { label: "Ille-et-Vilaine", codeRegion: "35", heure:1220 },
  { label: "Mayenne", codeRegion: "53", heure:1220 },
  { label: "Sarthe", codeRegion: "72", heure:1230 },
  { label: "Gers", codeRegion: "32", heure:1400 },
  { label: "Eure-et-Loir", codeRegion: "28", heure:1220 },
  { label: "Puy-de-Dôme", codeRegion: "63" , heure:1300},
  { label: "Haute-Saône", codeRegion: "70", heure:1220 },
  { label: "Orne", codeRegion: "61" , heure:1180},
  { label: "Haute-Savoie", codeRegion: "74", heure:1310 },
  { label: "Essonne", codeRegion: "91", heure:1210 },
  { label: "Yvelines", codeRegion: "78" , heure:1180},
  { label: "Calvados", codeRegion: "14", heure:1170 },
  { label: "Val-de-Marne", codeRegion: "94", heure:1190 },
  { label: "Aube", codeRegion: "10", heure:1210 },
  { label: "Seine-et-Marne", codeRegion: "77", heure:1200 },
  { label: "Hauts-de-Seine", codeRegion: "92", heure:1190 },
  { label: "Paris", codeRegion: "75", heure:1190 },
  { label: "Seine-Saint-Denis", codeRegion: "93", heure:1180 },
  { label: "Allier", codeRegion: "03",  heure:1290},
  { label: "Haute-Loire", codeRegion: "43", heure:1330 },
  { label: "Jura", codeRegion: "39", heure:1270 },
  { label: "Pyrénées-Atlantiques", codeRegion: "64",heure:1340 },
  { label: "Savoie", codeRegion: "73", heure:1360 },
  { label: "Yonne", codeRegion: "89", heure:1210 },
  { label: "Doubs", codeRegion: "25", heure:1240 },
  { label: "Manche", codeRegion: "50", heure:1150 },
  { label: "Somme", codeRegion: "80", heure:1120 },
  { label: "Creuse", codeRegion: "23" ,heure:1260},
  { label: "Nièvre", codeRegion: "58", heure:1270 },
  { label: "Bas-Rhin", codeRegion: "67", heure:1190 },
  { label: "Moselle", codeRegion: "57", heure:1140 },
  { label: "Côtes-d'Armor", codeRegion: "22", heure:1200 },
  { label: "Pas-de-Calais", codeRegion: "62", heure:1110 },
  { label: "Haute-Garonne", codeRegion: "31", heure:1430 },
  { label: "Oise", codeRegion: "60", heure:1150 },
  { label: "Landes", codeRegion: "40", heure:1330 },
  { label: "Loire", codeRegion: "42", heure:1280 },
  { label: "Meurthe-et-Moselle", codeRegion: "54", heure:1170 },
  { label: "Val-d'Oise", codeRegion: "95", heure:1180 },
  { label: "Eure", codeRegion: "27", heure:1160 },
  { label: "Seine-Maritime", codeRegion: "76", heure:1140 },
  { label: "Aisne", codeRegion: "02", heure:1140 },
  { label: "Haut-Rhin", codeRegion: "68", heure:1200 },
  { label: "Marne", codeRegion: "51", heure:1170 },
  { label: "Finistère", codeRegion: "29", heure:1220 },
  { label: "Hautes-Pyrénées", codeRegion: "65", heure:1350 },
  { label: "Nord", codeRegion: "59", heure:1090 },
  { label: "Vosges", codeRegion: "88", heure:1150 },
  { label: "Meuse", codeRegion: "55", heure:1140 },
];





