export const PRIX_KWH = 0.1822;

//detail paneau solaire
export const PUISSANCE_PANEAUX = 375;
export const TYPE_PANEAUX = "mono";
export const TYPE_ONDULEUR = "micro-onduleurs";
export const DONT_TAX = 9;

export const PRIX_8_PV = 8990;
export const PRIX_10_PV = 10990;
export const PRIX_12_PV = 12990;
export const PRIX_16_PV = 15590;
export const PRIX_24_PV = 19490;

export const PRODUCTION_PAR_HEURE_ENSOLEILLEMENT_8_PV = 3;
export const PRODUCTION_PAR_HEURE_ENSOLEILLEMENT_10_PV = 3.75;
export const PRODUCTION_PAR_HEURE_ENSOLEILLEMENT_12_PV = 4.5;
export const PRODUCTION_PAR_HEURE_ENSOLEILLEMENT_16_PV = 6;
export const PRODUCTION_PAR_HEURE_ENSOLEILLEMENT_24_PV = 9;
export const COEFICIENT_TOIT_MAISON = 1.054;

export const COEF_ORIENTATION = {
  Sud: 1,
  "Sud-Est / Sud-Ouest": 0.96,
  "Est / Ouest": 0.9,
};

export const CONSOMMATION = {
  Refrigerateur: 310,
  Congelateur: 300,
  Lave_vaisselle: 255,
  Lave_linge: 191,
  Seche_linge: 355,
  Piscine: 1251,
  Pompe_a_chaleur_piscine: 1289,
  Voiture_electrique: 2305,
  Chauffage_gaz_fioul_bois: 110,
  Chauffage_electrique: 110,
  Chauffage_PAC_air_eau: 51,
  Chauffage_PAC_air_air: 51,
  Chauffe_eau_electrique: 800,
  Chauffe_eau_gaz_fioul: 800,
  Ballon_thermodynamique: 1050,
};
