import { TextField } from "@mui/material";
import React from "react";

function CustomInput({ id, label, value, setValue, isNumber }) {
  const handleOnChange = (e) => {
    setValue(e.target.value);
  };
  return (
    <TextField
      type={isNumber ? "number" : "text"}
      style={{ width: "100%" }}
      id={id}
      label={label}
      variant="standard"
      fullWidth
      value={value}
      onChange={handleOnChange}
    />
  );
}

export default CustomInput;
