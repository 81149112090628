import { createTheme } from "@mui/material/styles";
import { green, purple } from "@mui/material/colors";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#f49c0a",
    },
    secondary: {
      main: green[500],
    },
    white: {
      main: "#ffffff",
    },
    grey: {
      main: "#b9b9b9",
    },
    active: {
      main: "#f49c0a",
      contrastText: "#ffffff",
    },
  },
});
