export const TEXT_IMAGE = [
  {
    text: "L'âge de votre maison a une incidence sur le taux de TVA appliqué dans le cadre de certains travaux comme l'installation d'une centrale photovoltaïque : pour une maison de plus de 2 ans vous bénéficiez d'une TVA à 10%.",
    step: 4,
  },
  {
    text: "La taille de votre logement a un impact sur votre consommation.",
    step: 5,
  },
  {
    text: "Le nombre d'occupants a un impact sur votre consommation.",
    step: 6,
  },
  {
    text: "Le soleil brille plus ou moins en fonction de votre emplacement.",
    step: 7,
  },
  {
    text: "L'exposition et l'inclinaison ont un impact sur la production du système. Si votre toit est plat, l'installation est un peu plus compliquée, nous vous l'expliquerons au téléphone.",
    step: 8,
    isAddressNotFound: true,
  },
  {
    text: "Nous vous demandons ici de réaliser La sélection de votre toiture. Nous en déduirons alors sa surface ainsi que son orientation, qui sont tous deux des paramètres nécessaire au dimensionnement de votre centrale photovoltaïque optimale.",
    step: 8,
  },
  { 
    text: "La taille de votre toiture conditionne le nombre de panneaux que l'on peut installer.",
    step: 9,
    isAddressNotFound: true,
  },
  {
    text: "L'exposition et l'inclinaison ont un impact sur la production du système. Si votre toit est plat, l'installation est un peu plus compliquée, nous vous l'expliquerons au téléphone.",
    step: 9,
  },
  {
    text: "Le prix de l'électricité varie en fonction de votre fournisseur d'énergie.",
    step: 10,
  },
  {
    text: "Votre mode de chauffage a un impact important sur votre facture et les économies que vous pourriez réaliser.",
    step: 11,
  },
  {
    text: "Votre mode de chauffage de l'eau a un impact important sur votre facture et les économies que vous pourriez réaliser.",
    step: 12,
  },
  {
    text: "Vos appareils énergivores ont impact important sur votre facture et les économies que vous pourriez réaliser.",
    step: 13,
  },
  {
    text: "Si vous ne connaissez pas le montant de votre facture, ce n'est pas grave. Nous l'estimons avec les questions précédentes.",
    step: 14,
  },
  {
    text: "Dernière étape. Nous sommes prêts à vous envoyer par email votre rapport personnalisé qui vous précisera la rentabilité de votre projet et ses caractéristiques techniques (puissance d'installation solaire, montant des économies à 25 ans, montant des aides publiques...). Ce document est indispensable pour pouvoir échanger correctement avec votre futur installateur. Conservez-le bien.",
    step: 17,
  },
 
];
