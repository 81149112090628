import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

function Question18({
  isFirst,
  isLast,
  next,
  previous,
  choice,
  setChoice,
  handleSubmit,
  isSelected,
  setQuestionToValue,
  form,
  handleChangeContainer,
}) {
  const handleChange = (event) => {
    setChoice({
      ...choice,
      [event.target.name]: event.target.checked,
    });
  };
  const [submitting, setSubmitting] = React.useState(false);
  const submit = () => {
    handleSubmit();
    setSubmitting(true);
    handleChangeContainer(4);
  };
  const cardRef = React.useRef(null);

  useEffect(() => {
    if (isSelected) {
      cardRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [isSelected]);
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const [vertical, setvertical] = useState("top");
  const [horizontal, sethorizontal] = useState("center");
  const initialChoice = {
    prenom: "",
    nom: "",
    telephone: "",
    email: "",
    adresse: "",
    adresse2: "",
    codePostal: "",
    ville: "",
    codeParrainage: "",
  };
  const handleClose = (event, reason) => {
    if (
      reason === "clickaway" ||
      reason === "autoHideDuration" ||
      reason === "backdropClick"
    ) {
      return;
    }

    setOpenSnackBar(false);
  };

  return (
    <>
      <Card
        sx={{ padding: "1rem" }}
        variant="outlined"
        ref={cardRef}
        className={isSelected ? "ae-card selected" : "ae-card"}
        onClick={() => setQuestionToValue(19)}
      >
        <CardHeader title="Finalisons votre rapport personnalisé" />
        <CardContent
          sx={{
            width: "100%",
          }}
        >
          <FormControl component="fieldset" variant="standard">
            <FormGroup style={{ display: "flex" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={choice.acceptData}
                    onChange={handleChange}
                    name="acceptData"
                  />
                }
                label={
                  <Typography variant="body2" gutterBottom>
                    J’ai lu et j’accepte que les données recueillies dans ce
                    simulateur soient traitées par Infinity Energie Groupe pour
                    faire une étude détaillée de mon projet et mener à bien
                    l’éligibilité, la vente et l’installation d’une centrale
                    solaire par un professionnel photovoltaïque.
                  </Typography>
                }
                labelPlacement="end"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={choice.shareData}
                    onChange={handleChange}
                    name="shareData"
                  />
                }
                label={
                  <Typography variant="body2" gutterBottom>
                    J’accepte que mes données soient partagées avec Infinity Energie Groupe en vue de recevoir des communications
                    personnalisées et de bénéficier d’avantages
                  </Typography>
                }
                labelPlacement="end"
              />
            </FormGroup>
          </FormControl>
        </CardContent>
        <CardActions
          style={{
            display: "flex",
            justifyContent: "end",
            padding: "1em",
          }}
        >
          <Button
            style={{ textAlign: "start" }}
            variant="contained"
            disabled={!choice.acceptData || submitting}
            onClick={(e) => {
              e.stopPropagation();
              //setQuestionToValue(19);
              if (
                form.prenom === "" ||
                form.nom === "" ||
                form.email === "" ||
                form.telephone === "" ||
                form.adresse === "" ||
                form.codePostal === "" ||
                form.ville === ""
                // ||
                // form.code_parrainage === "" ||
                // !form.code_parrainage
              ) {
                setOpenSnackBar(true);
              } else submit();
            }}
          >
            Valider et obtenir mon étude personnalisée
            <NavigateNextIcon color="black" />
          </Button>
        </CardActions>
      </Card>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={handleClose}
        vertical="top"
        horizontal="center"
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Vérifiez que vous avez bien rempli les champs obligatoires
        </Alert>
      </Snackbar>
    </>
  );
}

export default Question18;
