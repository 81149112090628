import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import img1 from "../../assets/QuestionImages/q16_m1.png";
import img2 from "../../assets/QuestionImages/q16_m2.png";
import img3 from "../../assets/QuestionImages/q16_m3.png";
import { useMediaQuery } from "react-responsive";
import useOffre from "../../Calcul/Offre";

function Question16({
  isFirst,
  isLast,
  next,
  previous,
  choice,
  setChoice,
  Store,
  isSelected,
  setQuestionToValue,
}) {
  const showMobile = useMediaQuery({ minWidth: 1290 });
  const currentyear = new Date().getFullYear();
  const { valorisationYear1, valorisationYear20, prime } = useOffre({ Store });
  const cardRef = React.useRef(null);

  useEffect(() => {
    if (isSelected) {
      cardRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [isSelected]);
  return (
    <Card
      sx={{ padding: "1rem" }}
      variant="outlined"
      ref={cardRef}
      className={isSelected ? "ae-card selected" : "ae-card"}
      onClick={() => setQuestionToValue(16)}
    >
      <CardHeader title="Détail de l'offre" />
      <CardContent
        sx={{
          width: "100%",
        }}
      >
        <Grid container spacing={2} style={{ padding: "0 65px 0 65px" }}>
          <Grid item xs={showMobile ? 4 : 12}>
            <Card
              style={{
                textAlign: "center",
                height: "100%",
              }}
            >
              <CardContent>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Avatar
                    alt="Remy Sharp"
                    src={img1}
                    sx={{ width: 100, height: 100 }}
                  />
                </div>
                <Typography variant="h4" component="div">
                  {valorisationYear1} €
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Valorisation de la production la première année
                </Typography>
                <Typography variant="body2">
                  En {currentyear}, vous pourrez réaliser jusqu’à{" "}
                  {valorisationYear1} € d’économies d’énergie liées à
                  l’autoconsommation et la vente de surplus
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={showMobile ? 4 : 12}>
            <Card
              style={{
                textAlign: "center",

                height: "100%",
              }}
            >
              <CardContent>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Avatar
                    alt="Remy Sharp"
                    src={img2}
                    sx={{ width: 100, height: 100 }}
                  />
                </div>
                <Typography variant="h4" component="div">
                  {valorisationYear20} €
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Valorisation de la production sur 20 ans
                </Typography>
                <Typography variant="body2">
                  En {currentyear + 20}, vous aurez réalisé jusqu’à{" "}
                  {valorisationYear20} € d’économies d’énergie liées à
                  l’autoconsommation et la vente de surplus
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={showMobile ? 4 : 12}>
            <Card
              style={{
                textAlign: "center",

                height: "100%",
              }}
            >
              <CardContent>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Avatar
                    alt="Remy Sharp"
                    src={img3}
                    sx={{ width: 100, height: 100 }}
                  />
                </div>
                <Typography variant="h4" component="div">
                  {prime} €
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  d'aides et subventions publiques
                </Typography>
                <Typography variant="body2">
                  Vous pourriez prétendre à {prime} € maximum d'aides et
                  subventions publiques
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions
        style={{
          display: "flex",
          justifyContent: "end",

          padding: "1em",
        }}
      >
        <Button
          style={{ textAlign: "start" }}
          variant="contained"
          disabled={isLast}
          onClick={(e) => {
            e.stopPropagation();
            setQuestionToValue(17);

            //next();
          }}
        >
          Suivant
          <NavigateNextIcon color="black" />
        </Button>
      </CardActions>
    </Card>
  );
}

export default Question16;
