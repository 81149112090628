import { Fab } from "@mui/material";
import React, { useEffect, useState } from "react";

function CustomElectromenagerInputs({ value, setValue }) {
  const [activeBtn1, setActiveBtn1] = useState(false);
  const [activeBtn2, setActiveBtn2] = useState(false);
  const [activeBtn3, setActiveBtn3] = useState(false);
  const [activeBtn4, setActiveBtn4] = useState(false);

  useEffect(() => {
    let mount = true;
    if (value === "Aucun" && mount) {
      setActiveBtn1(true);
      setActiveBtn2(false);
      setActiveBtn3(false);
      setActiveBtn4(false);
    } else if (value === 1 && mount) {
      setActiveBtn1(false);
      setActiveBtn2(true);
      setActiveBtn3(false);
      setActiveBtn4(false);
    } else if (value === 2 && mount) {
      setActiveBtn1(false);
      setActiveBtn2(false);
      setActiveBtn3(true);
      setActiveBtn4(false);
    } else if (value === 3 && mount) {
      setActiveBtn1(false);
      setActiveBtn2(false);
      setActiveBtn3(false);
      setActiveBtn4(true);
    }
    return () => {
      mount = false;
    };
  }, [value]);

  const handleCheckBtn1 = () => {
    if (activeBtn1) {
      setActiveBtn1(false);
      setValue(0);
    } else {
      setActiveBtn1(true);
      setValue(0);
    }
    setActiveBtn2(false);
    setActiveBtn3(false);
    setActiveBtn4(false);
  };
  const handleCheckBtn2 = () => {
    if (activeBtn2) {
      setActiveBtn2(false);
      setValue(0);
    } else {
      setActiveBtn2(true);
      setValue(1);
    }
    setActiveBtn1(false);
    setActiveBtn3(false);
    setActiveBtn4(false);
  };
  const handleCheckBtn3 = () => {
    if (activeBtn3) {
      setActiveBtn3(false);
      setValue(0);
    } else {
      setActiveBtn3(true);
      setValue(2);
    }
    setActiveBtn1(false);
    setActiveBtn2(false);
    setActiveBtn4(false);
  };
  const handleCheckBtn4 = () => {
    if (activeBtn4) {
      setActiveBtn4(false);
      setValue(0);
    } else {
      setActiveBtn4(true);
      setValue(3);
    }
    setActiveBtn1(false);
    setActiveBtn2(false);
    setActiveBtn3(false);
  };
  return (
    <>
      <Fab
        variant="extended"
        sx={{
          "&:hover": {
            backgroundColor: "primary.main",
          },
          mr: 1,
          ml: 5,
          height: "2.5em",
          //  width: 70,
        }}
        color={activeBtn1 ? "primary" : "white"}
        aria-label="persone1"
        size="small"
        onClick={handleCheckBtn1}
      >
        Aucun
      </Fab>

      <Fab
        color={activeBtn2 ? "primary" : "white"}
        aria-label="persone2"
        size="small"
        sx={{
          "&:hover": {
            backgroundColor: "primary.main",
          },
          mr: 1,
        }}
        onClick={handleCheckBtn2}
      >
        1
      </Fab>

      <Fab
        color={activeBtn3 ? "primary" : "white"}
        aria-label="persone3"
        size="small"
        sx={{
          "&:hover": {
            backgroundColor: "primary.main",
          },
          mr: 1,
        }}
        onClick={handleCheckBtn3}
      >
        2
      </Fab>
      <Fab
        color={activeBtn4 ? "primary" : "white"}
        aria-label="persone4"
        size="small"
        sx={{
          "&:hover": {
            backgroundColor: "primary.main",
          },
          mr: 1,
        }}
        onClick={handleCheckBtn4}
      >
        3+
      </Fab>
    </>
  );
}

export default CustomElectromenagerInputs;
