import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

const libraries = ["places"];

function AutoCompleteMaps({
  center,
  setCenter,
  setChoice,
  isInFrance,
  setisInFrance,
}) {
  const [isMounted, setIsMounted] = useState(false);
  const [autocomplete, setAutocomplete] = useState(null);

  useEffect(() => setIsMounted(true), []);

  /*  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    libraries,
  }); */

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      var place = autocomplete.getPlace();
      // get lat
      var lat = place.geometry.location.lat();
      // get lng
      var lng = place.geometry.location.lng();
      setCenter({ lat: lat, lng: lng });
      //setChoice({ label: place.formatted_address, heure: 2000 });
      const localtedInFrace = place.address_components.some((a) => {
        return a.long_name === "France";
      });
      setisInFrance(localtedInFrace ? 1 : 0);
    } else {
    }
  };
  /*  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  } */

  const renderAutoComplete = () => {
    const onLoad = (autocomplete) => {
      setAutocomplete(autocomplete);
    };
    return (
      <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
        <input
          type="text"
          placeholder="Saisissez votre adresse"
          style={{
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            width: `calc(100% - 2em)`,
            padding: `12px`,
            borderRadius: `3px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `17px`,
            outline: `none`,
            textOverflow: `ellipses`,
          }}
        />
      </Autocomplete>
    );
  };

  return renderAutoComplete();
}

export default AutoCompleteMaps;
