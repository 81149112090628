import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  /**
   // ! Polygone didnt show when using strict mode
   // ! issue URL : https://codesandbox.io/s/react-google-maps-api-forked-cj5vcw?file=/src/index.js:1326-1334
  <React.StrictMode>
    <App />
  </React.StrictMode>
   */
  <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
