import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from "@mui/material";
import React, { useEffect } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CustomInput from "./../CustomInputs/CustomInput";
function Question5({
  isFirst,
  isLast,
  next,
  previous,
  choice,
  setChoice,
  setQuestionToValue,
  isSelected,
}) {
  const cardRef = React.useRef(null);

  useEffect(() => {
    if (isSelected) {
      cardRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [isSelected]);
  return (
    <Card
      ref={cardRef}
      className={isSelected ? "ae-card selected" : "ae-card"}
      variant="outlined"
      onClick={() => setQuestionToValue(5)}
    >
      <CardHeader title="Quelle est la surface habitable de votre logement ?" />
      <CardContent
        style={{
          width: "100%",

          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          padding: "1.7em 0 1.7em 2.5em",
        }}
      >
        <CustomInput
          id={"Surface"}
          label={"Saisissez la surface de votre logement en m²"}
          value={choice}
          setValue={setChoice}
          isNumber={true}
        />
      </CardContent>
      <CardActions
        style={{
          display: "flex",
          justifyContent: "end",
          padding: "1em",
        }}
      >
        <Button
          style={{ textAlign: "end" }}
          variant="contained"
          endIcon={<NavigateNextIcon color="black" />}
          disabled={choice === "" || isLast}
          onClick={(e) => {
            e.stopPropagation();
            next(0);
          }}
        >
          Suivant
        </Button>
      </CardActions>
    </Card>
  );
}

export default Question5;
