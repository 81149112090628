import { Autocomplete, TextField } from "@mui/material";
import React from "react";

function CustomSelectWithSearch({ options, label, setValue, value }) {
  const defaultProps = {
    options: options,
    getOptionLabel: (option) => (option?.label ? option?.label : ""),
  };
  const onChangeProp = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Autocomplete
      {...defaultProps}
      noOptionsText="Pas d'autre choix"
      fullWidth
      id="disable-clearable"
      //clearOnEscape

      onChange={onChangeProp}
      placeholder={"hello"}
      value={value}
      renderInput={(params) => (
        <TextField {...params} label={label} variant="standard" />
      )}
    />
  );
}

export default CustomSelectWithSearch;
