import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Fab,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

function Question6({
  isFirst,
  isLast,
  next,
  previous,
  choice,
  setChoice,
  setQuestionToValue,
  isSelected,
}) {
  const [persone1, setpersone1] = useState(false);
  const [persone2, setpersone2] = useState(false);
  const [persone3, setpersone3] = useState(false);
  const [persone4, setpersone4] = useState(false);
  const [persone5, setpersone5] = useState(false);

  useEffect(() => {
    let mount = true;
    if (choice === 1 && mount) {
      setpersone1(true);
      setpersone2(false);
      setpersone3(false);
      setpersone4(false);
      setpersone5(false);
    } else if (choice === 2 && mount) {
      setpersone1(false);
      setpersone2(true);
      setpersone3(false);
      setpersone4(false);
      setpersone5(false);
    } else if (choice === 3 && mount) {
      setpersone1(false);
      setpersone2(false);
      setpersone3(true);
      setpersone4(false);
      setpersone5(false);
    } else if (choice === 4 && mount) {
      setpersone1(false);
      setpersone2(false);
      setpersone3(false);
      setpersone4(true);
      setpersone5(false);
    } else if (choice === 5 && mount) {
      setpersone1(false);
      setpersone2(false);
      setpersone3(false);
      setpersone4(false);
      setpersone5(true);
    }
    return () => {
      mount = false;
    };
  }, [choice]);

  const handleCheckPersonne1 = () => {
    if (persone1) {
      setpersone1(false);
      setChoice(0);
    } else {
      setpersone1(true);
      setChoice(1);
    }

    setpersone2(false);
    setpersone3(false);
    setpersone4(false);
    setpersone5(false);
  };
  const handleCheckPersonne2 = () => {
    setpersone1(false);

    setpersone3(false);
    setpersone4(false);
    setpersone5(false);
    if (persone2) {
      setpersone2(false);
      setChoice(0);
    } else {
      setpersone2(true);
      setChoice(2);
    }
  };
  const handleCheckPersonne3 = () => {
    setpersone1(false);
    setpersone2(false);

    setpersone4(false);
    setpersone5(false);
    if (persone3) {
      setpersone3(false);
      setChoice(0);
    } else {
      setpersone3(true);
      setChoice(3);
    }
  };
  const handleCheckPersonne4 = () => {
    setpersone1(false);
    setpersone2(false);
    setpersone3(false);

    setpersone5(false);
    if (persone4) {
      setpersone4(false);
      setChoice(0);
    } else {
      setpersone4(true);
      setChoice(4);
    }
  };
  const handleCheckPersonne5 = () => {
    setpersone1(false);
    setpersone2(false);
    setpersone3(false);
    setpersone4(false);
    if (persone5) {
      setpersone5(false);
      setChoice(0);
    } else {
      setpersone5(true);
      setChoice(5);
    }
  };
  const cardRef = React.useRef(null);

  useEffect(() => {
    if (isSelected) {
      cardRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [isSelected]);
  return (
    <Card
      ref={cardRef}
      className={isSelected ? "ae-card selected" : "ae-card"}
      variant="outlined"
      onClick={() => setQuestionToValue(6)}
    >
      <CardHeader title="En moyenne combien de personnes occupent votre logement à l´année ?" />
      <CardContent
        style={{
          width: "100%",

          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          padding: "1.7em 0 1.7em 2.5em",
        }}
      >
        <div>
          <Fab variant="extended" sx={{ mr: 3 }}>
            <AccountCircleIcon sx={{ mr: 2 }} />
            Personnes : {choice}
          </Fab>

          <Fab
            sx={{
              "&:hover": {
                backgroundColor: "primary.main",
              },
              mr: 1,
            }}
            color={persone1 ? "primary" : "white"}
            aria-label="persone1"
            size="small"
            onClick={(e) => {
              handleCheckPersonne1();
              e.stopPropagation();
              next(6);
            }}
          >
            1
          </Fab>

          <Fab
            color={persone2 ? "primary" : "white"}
            aria-label="persone2"
            size="small"
            sx={{
              "&:hover": {
                backgroundColor: "primary.main",
              },
              mr: 1,
            }}
            onClick={(e) => {
              handleCheckPersonne2();
              e.stopPropagation();
              next(6);
            }}
          >
            2
          </Fab>

          <Fab
            color={persone3 ? "primary" : "white"}
            aria-label="persone3"
            size="small"
            sx={{
              "&:hover": {
                backgroundColor: "primary.main",
              },
              mr: 1,
            }}
            onClick={(e) => {
              handleCheckPersonne3();
              e.stopPropagation();
              next(6);
            }}
          >
            3
          </Fab>

          <Fab
            color={persone4 ? "primary" : "white"}
            aria-label="persone4"
            size="small"
            sx={{
              "&:hover": {
                backgroundColor: "primary.main",
              },
              mr: 1,
            }}
            onClick={(e) => {
              handleCheckPersonne4();
              e.stopPropagation();
              next(6);
            }}
          >
            4
          </Fab>

          <Fab
            color={persone5 ? "primary" : "white"}
            aria-label="persone5"
            size="small"
            sx={{
              "&:hover": {
                backgroundColor: "primary.main",
              },
              mr: 1,
            }}
            onClick={(e) => {
              handleCheckPersonne5();
              e.stopPropagation();
              next(6);
            }}
          >
            5+
          </Fab>
          {!choice ||
          (choice === 0 && (
            <Alert severity="error">
              Vous devez séléctionner le nombre de personne pour continuer
            </Alert>
          ))}
        </div>
       
      </CardContent>
      {/*  <CardActions
        style={{
          display: "flex",
          justifyContent: "end",
          padding: "1em",
        }}
      >
        <Button
          style={{ textAlign: "end" }}
          variant="contained"
          endIcon={<NavigateNextIcon color="white" />}
          disabled={!choice || choice === 0 || isLast}
          onClick={(e) => {
            e.stopPropagation();
            next();
          }}
        >
          Suivant
        </Button>
      </CardActions> */}
    </Card>
  );
}

export default Question6;
