import React from "react";
import PromoCard from "../QuestionsCards/PromoCard";
import Question15 from "../QuestionsCards/Question15";
import Question16 from "../QuestionsCards/Question16";
import Question17 from "../QuestionsCards/Question17";
import Question18 from "../QuestionsCards/Question18";
import Question19 from "../QuestionsCards/Question19";

function ContainerProjet({
  Store,
  handleClickNext,
  handleClickPrevious,
  choiceQ18,
  setChoiceQ18,
  choiceQ14,
  setChoiceQ14,
  choiceQ17,
  setChoiceQ17,
  handleSubmit,
  questionCounter,
  setQuestionToValue,
  handleChangeContainer,
  setPromoOffre
}) {
  return (
    <>
      <Question15
        Store={Store}
        isFirst={false}
        isLast={false}
        next={handleClickNext}
        previous={handleClickPrevious}
        choice={choiceQ14}
        setChoice={setChoiceQ14}
        isSelected={questionCounter === 15}
        setQuestionToValue={setQuestionToValue}
      />
      <Question16
        Store={Store}
        isFirst={false}
        isLast={false}
        next={handleClickNext}
        previous={handleClickPrevious}
        choice={choiceQ14}
        setChoice={setChoiceQ14}
        isSelected={questionCounter === 16}
        setQuestionToValue={setQuestionToValue}
      />
      <PromoCard
        Store={Store}
        isSelected={questionCounter === 17}
        setQuestionToValue={setQuestionToValue}
        setPromoOffre={setPromoOffre}
      />
      <Question17
        Store={Store}
        isFirst={false}
        isLast={false}
        next={handleClickNext}
        previous={handleClickPrevious}
        choice={choiceQ17}
        setChoice={setChoiceQ17}
        isSelected={questionCounter === 18}
        setQuestionToValue={setQuestionToValue}
      />
      <Question18
        isFirst={false}
        isLast={false}
        next={handleClickNext}
        previous={handleClickPrevious}
        form={choiceQ17}
        choice={choiceQ18}
        setChoice={setChoiceQ18}
        handleSubmit={handleSubmit}
        isSelected={questionCounter === 19}
        setQuestionToValue={setQuestionToValue}
        handleChangeContainer={handleChangeContainer}
      />
    </>
  );
}

export default ContainerProjet;
