import {
  Autocomplete,
  Button,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../Context/GloablContext";
import axios from "axios";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const BACKEN_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_BACKEND_URL
    : process.env.REACT_APP_BACKEND_URL_DEV;

function ModalCodeParrainage() {
  const [open, setOpen] = useState(true);
  const [codes, setCodes] = useState([]);
  const [selectedCode, setSelectedCode] = useState(null);
  const context = useContext(GlobalContext);
  const fetchRegieAndSousRegie = async () => {
    const regies = await axios.get(`${BACKEN_URL}/api/regie_noPagination`);
    const sousRegies = await axios.get(
      `${BACKEN_URL}/api/sousRegie_noPagination`
    );
    const regiesAndSousRegies = [...regies.data.res, ...sousRegies.data.res];
    const test = regiesAndSousRegies.map((regie) => {
      return {
        value: regie.code,
        label: regie.code,
      };
    });
    setCodes(test);
  };
  useEffect(() => {
    fetchRegieAndSousRegie();
  }, []);

  const onClose = () => {
    setOpen(false);
    context.setCode(-1);
  };
  const onConfirm = () => {
    setOpen(false);
    context.setCode(selectedCode);
  };
  const handleChangeCode = (e, value) => {
    setSelectedCode(value.label);
  };
  return (
    <Modal
      open={open}
      //onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Choissisez votre code parrainnage
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
          Vous pouvez choisir aucun code parrainnage en cliquant sur le bouton
          annuler pour voir toutes les offres
        </Typography>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={codes}
          sx={{ width: 300, mb: 2 }}
          renderInput={(params) => (
            <TextField {...params} label="Code parrainnage" />
          )}
          onChange={(e, value) => {
            handleChangeCode(e, value);
          }}
        />
        <Button onClick={onClose}>Annuler</Button>
        <Button onClick={onConfirm}>Confirmer</Button>
      </Box>
    </Modal>
  );
}

export default ModalCodeParrainage;
