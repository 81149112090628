import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import CustomSelectWithSearch from "./../CustomInputs/CustomSelectWithSearch";
import { ListOfAddresses } from "../Imports/ListeOfAddresses";
import WbSunnyTwoToneIcon from "@mui/icons-material/WbSunnyTwoTone";

function Question7({
  isFirst,
  isLast,
  next,
  previous,
  choice,
  setChoice,
  setQuestionToValue,
  isSelected,
}) {
  // useEffect(() => {
  //   let mount = true;
  //   if (choice && mount) setChoice(choice);
  //   return () => {
  //     mount = false;
  //   };
  // }, [choice]);
  const cardRef = React.useRef(null);

  useEffect(() => {
    if (isSelected) {
      cardRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [isSelected]);
  return (
    <Card
      ref={cardRef}
      className={isSelected ? "ae-card selected" : "ae-card"}
      variant="outlined"
      onClick={() => {
        setQuestionToValue(7);
      }}
    >
      <CardHeader title="Quelle est votre adresse ?" />
      <CardContent
        style={{
          padding: "1em",
        }}
      >
        <CustomSelectWithSearch
          id={"Adresse"}
          label={"Choisissez votre adresse"}
          value={choice}
          setValue={setChoice}
          options={ListOfAddresses}
        />
        {choice && (
          <>
            <WbSunnyTwoToneIcon color="primary" sx={{ marginTop: ".7em" }} />
            <Typography variant="button" display="inline-block" gutterBottom>
              Soit {choice.heure} heures d'ensoleillement par an
            </Typography>
          </>
        )}
      </CardContent>

      <CardActions
        style={{
          display: "flex",
          justifyContent: "end",

          padding: "1em",
        }}
      >
        <Button
          style={{ textAlign: "end" }}
          variant="contained"
          endIcon={<NavigateNextIcon color="black " />}
          disabled={choice === "" || choice === null || isLast}
          onClick={(e) => {
            e.stopPropagation();
            next(7);
          }}
        >
          Suivant
        </Button>
      </CardActions>
    </Card>
  );
}

export default Question7;
