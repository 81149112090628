import axios from "axios";
import React, { createContext, useEffect, useState } from "react";

export const GlobalContext = createContext();
const BACKEN_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_BACKEND_URL
    : process.env.REACT_APP_BACKEND_URL_DEV;

function GloablContextProvider({ children }) {
  const [store, setStore] = useState({
    loading: true,
    offres: [],
  });
  const [code, setCode] = useState(null);

  const getOffres = async (code) => {
    try {
      const { data } = await axios.post(`${BACKEN_URL}/api/offre/byCode`, {
        code: code === -1 ? null : code,
      });
      const sortedData = data.sort((a, b) => a.surfaceToit - b.surfaceToit);

      setStore((prev) => ({ ...prev, offres: sortedData, loading: false }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (code) getOffres(code);
  }, [code]);

  return (
    <GlobalContext.Provider value={{ store, setCode, code }}>
      {children}
    </GlobalContext.Provider>
  );
}

export default GloablContextProvider;
