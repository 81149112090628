import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from "@mui/material";
import React, { useEffect } from "react";

function Question19({ isSelected, setQuestionToValue }) {
  const cardRef = React.useRef(null);

  useEffect(() => {
    if (isSelected) {
      cardRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [isSelected]);
  return (
    <Card
      sx={{ padding: "1rem" }}
      variant="outlined"
      ref={cardRef}
      className={"ae-card selected"}
      onClick={() => setQuestionToValue(19)}
    >
      <CardHeader title="Simulation enregistrée" />
      <CardContent
        sx={{
          width: "100%",
        }}
      >
        <p style={{ textAlign: "center" }}>
          Nous vous remercions pour votre demande.
          <br />
          Découvrez sans tarder votre étude personnalisée envoyée dans votre
          boîte email.
          <br />A très bientôt !
        </p>
      </CardContent>
      <CardActions
        style={{
          display: "flex",
          justifyContent: "space-between",
          //marginTop: "1.5em",
          padding: "1em",
        }}
      >
        <span />

        <Button
          style={{ textAlign: "start" }}
          variant="contained"
          onClick={() => window.location.reload()}
        >
          Retour à l'accueil
        </Button>
      </CardActions>
    </Card>
  );
}

export default Question19;
