import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomButton from "../CustomInputs/CustomButton";
import imgHouse2ansPlus from "../../assets/QuestionImages/q4_m1.png";
import imgHouse2AnsMinus from "../../assets/QuestionImages/q4_m2.png";
import imgHouseConstruction from "../../assets/QuestionImages/q4_m3.png";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

function Question4({
  isFirst,
  isLast,
  next,
  previous,
  choice,
  setChoice,
  setQuestionToValue,
  isSelected,
}) {
  const [activeBtn1, setActiveBtn1] = useState(false);
  const [activeBtn2, setActiveBtn2] = useState(false);
  const [activeBtn3, setActiveBtn3] = useState(false);

  useEffect(() => {
    let mount = true;
    if (choice === "Construite depuis plus de 2 ans" && mount) {
      setActiveBtn1(true);
      setActiveBtn2(false);
      setActiveBtn3(false);
    } else if (choice === "Construite depuis moins de 2 ans" && mount) {
      setActiveBtn1(false);
      setActiveBtn2(true);
      setActiveBtn3(false);
    } else if (choice === "Projet de construction" && mount) {
      setActiveBtn1(false);
      setActiveBtn2(false);
      setActiveBtn3(true);
    }
    return () => {
      mount = false;
    };
  }, [choice]);

  const handleClickBtn1 = () => {
    if (activeBtn1) {
      setActiveBtn1(false);
      setChoice("");
    } else {
      setActiveBtn1(true);
      setChoice("Construite depuis plus de 2 ans");
    }
    setActiveBtn2(false);
    setActiveBtn3(false);
  };
  const handleClickBtn2 = () => {
    if (activeBtn2) {
      setActiveBtn2(false);
      setChoice("");
    } else {
      setActiveBtn2(true);
      setChoice("Construite depuis moins de 2 ans");
    }
    setActiveBtn1(false);
    setActiveBtn3(false);
  };
  const handleClickBtn3 = () => {
    if (activeBtn3) {
      setActiveBtn3(false);
      setChoice("");
    } else {
      setActiveBtn3(true);
      setChoice("Projet de construction");
    }
    setActiveBtn1(false);
    setActiveBtn2(false);
  };
  const cardRef = React.useRef(null);

  useEffect(() => {
    if (isSelected) {
      cardRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [isSelected]);
  return (
    <Card
      ref={cardRef}
      className={isSelected ? "ae-card selected" : "ae-card"}
      variant="outlined"
      onClick={() => setQuestionToValue(4)}
    >
      <CardHeader title="Votre logement a-t-il été construit récemment ?" />
      <CardContent
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          padding: "0 0 0 2.5em",
        }}
      >
        <CustomButton
          img={imgHouse2ansPlus}
          active={activeBtn1}
          onClick={(e) => {
            handleClickBtn1();
            e.stopPropagation();
            next(4);
          }}
          buttonText="Construite depuis plus de 2 ans"
        />
        <CustomButton
          img={imgHouse2AnsMinus}
          active={activeBtn2}
          onClick={(e) => {
            handleClickBtn2();
            e.stopPropagation();
            next(4);
          }}
          buttonText="Construite depuis moins de 2 ans"
        />
        <CustomButton
          img={imgHouseConstruction}
          active={activeBtn3}
          onClick={(e) => {
            handleClickBtn3();
            e.stopPropagation();
            next(4);
          }}
          buttonText="Projet de construction"
        />
      </CardContent>
    </Card>
  );
}

export default Question4;
