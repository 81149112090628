import {
  Alert,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomButton from "../CustomInputs/CustomButton";
import imgHouse from "../../assets/QuestionImages/q1_m1.png";
import imgAppt from "../../assets/QuestionImages/q1_m2.png";

function Question1({
  isFirst,
  isLast,
  next,
  previous,
  choice,
  setChoice,
  isSelected,
  setQuestionToValue,
}) {
  const [activeBtn1, setActiveBtn1] = useState(false);
  const [activeBtn2, setActiveBtn2] = useState(false);
  useEffect(() => {
    let mount = true;
    if (choice === "Maison" && mount) {
      setActiveBtn1(true);
      setActiveBtn2(false);
    } else if (choice === "Appartement" && mount) {
      setActiveBtn1(false);
      setActiveBtn2(true);
    }
    return () => {
      mount = false;
    };
  }, [choice]);

  const handleClickBtn1 = () => {
    if (activeBtn1) {
      setActiveBtn1(false);
      setChoice("");
    } else {
      setActiveBtn1(true);
      setChoice("Maison");
    }
    setActiveBtn2(false);
  };
  const handleClickBtn2 = () => {
    if (activeBtn2) {
      setActiveBtn2(false);
      setChoice("");
    } else {
      setActiveBtn2(true);
      setChoice("Appartement");
    }
    setActiveBtn1(false);
  };
  const cardRef = React.useRef(null);

  useEffect(() => {
    if (isSelected) {
      cardRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [isSelected]);

  return (
    <Card
      ref={cardRef}
      className={isSelected ? "ae-card selected" : "ae-card"}
      variant="outlined"
      onClick={() => setQuestionToValue(1)}
    >
      <CardHeader title="Votre logement est-il une maison ou un appartement ?" />
      <CardContent
        style={{
          width: "100%",

          padding: "0 0 0 2.5em",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <CustomButton
            img={imgHouse}
            active={activeBtn1}
            onClick={(e) => {
              handleClickBtn1();
              e.stopPropagation();
              next(1);
            }}
            buttonText="Maison"
          />
          <CustomButton
            img={imgAppt}
            active={activeBtn2}
            onClick={handleClickBtn2}
            buttonText="Appartement"
          />
        </div>
      </CardContent>

      <CardActions
        style={{
          display: "flex",
          justifyContent: "start",
          padding: "1em",
        }}
      >
        {activeBtn2 && (
          <Alert severity="error">
            Notre solution d’autoconsommation ne s’adresse qu’aux propriétaires
            de maison individuelle.
          </Alert>
        )}
      </CardActions>
    </Card>
  );
}

export default Question1;
