import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Switch,
  Typography,
} from "@mui/material";
import React from "react";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import CustomElectromenagerInputs from "../CustomInputs/CustomElectromenagerInputs";
import { useEffect } from "react";

function Question12({
  isFirst,
  isLast,
  next,
  previous,
  choice,
  setChoice,
  isSelected,
  setQuestionToValue,
}) {
  const handleChange = (event) => {
    setChoice({
      ...choice,
      [event.target.name]: event.target.checked,
    });
  };

  const handleChangeChauffageValue = (event) => {
    setChoice({
      ...choice,
      ChauffeEau: event,
    });
  };
  const cardRef = React.useRef(null);

  useEffect(() => {
    if (isSelected) {
      cardRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [isSelected]);
  return (
    <Card
      sx={{ padding: "1rem" }}
      variant="outlined"
      ref={cardRef}
      className={isSelected ? "ae-card selected" : "ae-card"}
      onClick={() => setQuestionToValue(12)}
    >
      <CardHeader title="Quel est votre chauffage pour l´eau ?" />
      <CardContent
        style={{
          width: "100%",

          padding: "0 0 0 2.5em",
        }}
      >
        <div
          style={{
            display: "block",
            textAlign: "start",
            width: "70%",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1" gutterBottom>
              Chauffe-eau électrique :
            </Typography>

            <CustomElectromenagerInputs
              value={choice.ChauffeEau}
              setValue={handleChangeChauffageValue}
            />
          </div>

          <FormControl component="fieldset" variant="standard">
            {/*  <FormLabel component="legend">Assign responsibility</FormLabel> */}
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={choice.Ballon}
                    onChange={handleChange}
                    name="Ballon"
                  />
                }
                label="Ballon Thermodynamique"
                style={{ margin: ".3em" }}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={choice.ChauffageGaz}
                    onChange={handleChange}
                    name="ChauffageGaz"
                  />
                }
                label="Chauffe-eau Gaz, fioul ou bois"
                style={{ margin: ".3em" }}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={choice.Autre}
                    onChange={handleChange}
                    name="Autre"
                  />
                }
                label="Autre ou je ne sais pas"
                style={{ margin: ".3em" }}
              />
            </FormGroup>
            {/*  <FormHelperText>Be careful</FormHelperText> */}
          </FormControl>
        </div>
      </CardContent>
      <CardActions
        style={{
          display: "flex",
          justifyContent: "end",

          padding: "1em",
        }}
      >
        <Button
          style={{ textAlign: "start" }}
          variant="contained"
          disabled={choice.ChauffeEau === "" || isLast}
          onClick={(e) => {
            e.stopPropagation();
            next(0);
          }}
        >
          Suivant
          <NavigateNextIcon color="black" />
        </Button>
      </CardActions>
    </Card>
  );
}

export default Question12;
