import { Fab } from "@mui/material";
import React from "react";
function CustomButton({ img, active, buttonText, onClick }) {
  return (
    <div style={{ textAlign: "center", marginRight: "2em" }}>
      <Fab
        color={active ? "active" : "white"}
        aria-label="add"
        sx={{
          height: 100,
          width: 100,
          "&:hover": {
            backgroundColor: "active.main",
          },
        }}
        onClick={onClick}
      >
        <img
          alt="img"
          src={img}
          style={{ height: 60, width: 60 /*  opacity: 0.5 */ }}
        />
      </Fab>
      <br />
      <br />

      <span>{buttonText}</span>
    </div>
  );
}

export default CustomButton;
