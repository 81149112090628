import { Typography } from "@mui/material";
import React from "react";
import { useMediaQuery } from "react-responsive";

function Header2() {
  const showMobile = useMediaQuery({ minWidth: 1290 });

  return (
    <Typography
      className="footer2"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "4rem",
        fontSize: "1.5rem",
        fontWeight: "lighter",
        fontSize: showMobile ? "1.7em" : "1em",
      }}
      variant="h6"
      component="div"
    >
      L'autoconsommation solaire. Performante, rentable et clé en main avec
      Infinity Energie Groupe®
    </Typography>
  );
}

export default Header2;
