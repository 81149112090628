import React, { useEffect } from "react";
import {
  GoogleMap,
  InfoWindow,
  Polygon,
  useLoadScript,
  withGoogleMap,
} from "@react-google-maps/api";
import { useState } from "react";
import { useRef } from "react";
import { getEnsoleillement } from "../../Calcul/CalculSheet";

const mapContainerStyle = {
  height: "400px",
  width: "800px",
};
const options = {
  fillColor: "red",
  fillOpacity: 0.3,
  strokeColor: "#33677e",
  strokeOpacity: 1,
  strokeWeight: 2,
  clickable: true,
  draggable: false,
  editable: true,
  geodesic: true,
  zIndex: 1,
  visible: true,
};

const MapMarker = ({
  center,
  setCenter,
  path,
  setPath,
  setChoice,
  setChoiceQ6,
  choice,
}) => {
  const polygone = useRef(null);
  // const { isLoaded, loadError } = useLoadScript({
  //   googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
  // });
  const [showInfo, setshowInfo] = useState(false);
  const [area, setArea] = useState(0);
  const onLoad = () => {
    const test = new window.google.maps.Geocoder();
    test
      .geocode({
        location: center,
      })
      .then((res) => {
        //console.log("AutoComplete", res.results);
        splitAddress(res);
      });
  };

  const [isMounted, setIsMounted] = useState(false);

  const splitAddress = (res) => {
    const heure = getEnsoleillement(res);
    const street = res.results[0].address_components.find(
      (el) => el.types[0] === "street_number"
    );
    const streetName = res.results[0].address_components.find(
      (el) => el.types[0] === "route"
    );
    const city = res.results[0].address_components.find(
      (el) => el.types[0] === "locality"
    );
    const postalCode = res.results[0].address_components.find(
      (el) => el.types[0] === "postal_code"
    );

    console.log("Adress Obj", {
      heure: heure,
      label:
        street || streetName
          ? `${street?.long_name ? street?.long_name : ""} ${
              streetName?.long_name ? streetName?.long_name : ""
            }`
          : "",
      city: city?.long_name ? city?.long_name : "",
      postalCode: postalCode?.long_name ? postalCode?.long_name : "",
    });

    setChoiceQ6({
      heure: heure,
      label:
        street || streetName
          ? `${street?.long_name ? street?.long_name : ""} ${
              streetName?.long_name ? streetName?.long_name : ""
            }`
          : "",
      city: city?.long_name ? city?.long_name : "",
      postalCode: postalCode?.long_name ? postalCode?.long_name : "",
    });
  };

  useEffect(() => setIsMounted(true), []);
  // if (loadError) {
  //   return <div>Map cannot be loaded right now, sorry.</div>;
  // }
  return (
    <GoogleMap
      id="marker-example"
      mapContainerStyle={mapContainerStyle}
      center={center}
      zoom={20}
      mapTypeId="satellite"
      tilt={0}
      onMouseUp={(e) => {
        const test = new window.google.maps.Geocoder();
        test
          .geocode({
            location: e.latLng,
          })
          .then((res) => {
            splitAddress(res);
          });
      }}
    >
      <Polygon
        ref={polygone}
        onLoad={onLoad}
        paths={path}
        options={options}
        onMouseUp={(e) => {
          if (e.edge !== undefined) {
            // Edit edge midway points (broken)
            const nextPath = [...path];

            // Splice in new coordinates in the nextPath array
            nextPath.splice(e.edge + 1, 0, {
              lat: e.latLng.lat(),
              lng: e.latLng.lng(),
            });

            setPath(nextPath);
          } else if (e.vertex !== undefined) {
            // Edit vertex position (working)
            const nextPath = path.map((coord, index) => {
              if (index === e.vertex) {
                return { lat: e.latLng.lat(), lng: e.latLng.lng() };
              } else {
                return coord;
              }
            });
            setPath(nextPath);
          }
          setArea(
            Math.round(window.google.maps.geometry.spherical.computeArea(path))
          );
          setChoice(
            Math.round(
              window.google.maps.geometry.spherical.computeArea(path)
            ) + " m²"
          );

          setshowInfo(true);
        }}
      />

      {showInfo && (
        <InfoWindow
          onLoad={onLoad}
          position={path[0]}
          onCloseClick={() => setshowInfo(false)}
        >
          <div>
            <b>Surface:</b> {area} m²
          </div>
        </InfoWindow>
      )}
      <></>
    </GoogleMap>
  );
};

export default React.memo(MapMarker);
