import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Card,
  CardContent,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import "./App.css";
import Header from "./Components/Header/Header";
import HomeTwoToneIcon from "@mui/icons-material/HomeTwoTone";
import InventoryTwoToneIcon from "@mui/icons-material/InventoryTwoTone";
import PowerTwoToneIcon from "@mui/icons-material/PowerTwoTone";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./Components/theme";
import Header2 from "./Components/Header/Header2";
import Footer from "./Components/Footer/Footer";
import panneau from "./assets/inspi1.jpg";
import projet from "./assets/inspi3.jpg";
import consommation from "./assets/inspi2.jpeg";
import logoSM from "./assets/logoSM.png";
import { useMediaQuery } from "react-responsive";

import { TEXT_IMAGE } from "./Calcul/TextImage";
import ContainerLogement from "./Components/Containers/ContainerLogement";
import ContainerConsommation from "./Components/Containers/ContainerConsommation";
import ContainerProjet from "./Components/Containers/ContainerProjet";
import Question19 from "./Components/QuestionsCards/Question19";
import GloablContextProvider, { GlobalContext } from "./Context/GloablContext";
import ModalCodeParrainage from "./Components/ModalCodeParrainage/ModalCodeParrainage";

const BACKEN_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_BACKEND_URL
    : process.env.REACT_APP_BACKEND_URL_DEV;

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GloablContextProvider>
        <ModalCodeParrainage />
        <Simulateur />
      </GloablContextProvider>
    </ThemeProvider>
  );
}

export default App;

const Simulateur = () => {
  const context = useContext(GlobalContext);
  const showPicture = useMediaQuery({ minWidth: 1290 });
  const [questionCounter, setQuestionCounter] = useState(1);
  const [value, setValue] = useState(0);
  const [center, setCenter] = useState({
    lat: 48.864716,
    lng: 2.349014,
  });
  const [containerCounter, setContainerCounter] = useState(1);
  const [visitedConsommation, setVisitedConsommation] = useState(false);
  const [visitedProjet, setVisitedProjet] = useState(false);
  const handleClickNext = (questionNumber) => {
    if (questionCounter < 20)
      setQuestionCounter(
        questionNumber === 0 ? questionCounter + 1 : questionNumber + 1
      );
    if (
      [1, 2, 3, 4, 5, 6, 7, 8, 9].includes(
        questionNumber === 0 ? questionCounter + 1 : questionNumber + 1
      )
    )
      setValue(0);
    if (
      [10, 11, 12, 13, 14].includes(
        questionNumber === 0 ? questionCounter + 1 : questionNumber + 1
      )
    )
      setValue(1);
    if (
      [15, 16, 17, 18].includes(
        questionNumber === 0 ? questionCounter + 1 : questionNumber + 1
      )
    )
      setValue(2);
    if (containerCounter === 2) setVisitedConsommation(true);
    if (containerCounter === 3) setVisitedProjet(true);
  };
  const handleVisitConsommation = () => {
    setVisitedConsommation(true);
  };
  const handleVisitProjet = () => {
    setVisitedProjet(true);
  };
  const handleClickPrevious = () => {
    if (questionCounter > 1) setQuestionCounter(questionCounter - 1);
    if ([1, 2, 3, 4, 5, 6, 7, 8, 9].includes(questionCounter - 1)) setValue(0);
    if ([10, 11, 12, 13, 14].includes(questionCounter - 1)) setValue(1);
    if ([15, 16, 17, 18].includes(questionCounter - 1)) setValue(2);
  };

  const setQuestionToValue = (value) => {
    setQuestionCounter(value);
    if ([1, 2, 3, 4, 5, 6, 7, 8, 9].includes(value)) setValue(0);
    if ([10, 11, 12, 13, 14].includes(value)) setValue(1);
    if ([15, 16, 17, 18].includes(value)) setValue(2);
  };

  const [choiceQ1, setChoiceQ1] = useState("");
  const [choiceQ2, setChoiceQ2] = useState("");
  const [choiceQ3, setChoiceQ3] = useState("");
  const [choiceQ4, setChoiceQ4] = useState("");
  const [choiceQ5, setChoiceQ5] = useState("");
  const [choiceQ6, setChoiceQ6] = useState(145);

  const [choiceQ7, setChoiceQ7] = useState(null);
  const [choiceQ8, setChoiceQ8] = useState("");
  const [choiceQ9, setChoiceQ9] = useState("");
  const [choiceQ10, setChoiceQ10] = useState("");
  const [choiceQ11, setChoiceQ11] = useState({
    ChauffageElectrique: false,
    Climatisation: false,
    Pompe: false,
    ChauffageGaz: false,
  });
  const [choiceQ12, setChoiceQ12] = useState({
    Ballon: false,
    ChauffeEau: 0,
    Autre: false,
    ChauffageGaz: false,
  });
  const [choiceQ13, setChoiceQ13] = useState({
    Refrigerateur: 1,
    Congelateur: 1,
    LaveVaisselle: 1,
    LaveLinge: 1,
    SecheLinges: 1,
    Piscine: 0,
    PompePiscine: 0,
    voitureElectrique: 0,
  });
  const [choiceQ14, setChoiceQ14] = useState({
    selected: "facture",
    facture: "",
    consommation: "",
  });
  const [choiceQ17, setChoiceQ17] = useState({
    prenom: "",
    nom: "",
    telephone: "",
    email: "",
    adresse: "",
    adresse2: "",
    codePostal: "",
    ville: "",
    code_parrainage: null,
  });
  const [choiceQ18, setChoiceQ18] = useState({
    acceptData: false,
    shareData: false,
  });
  const [promoOffre, setPromoOffre] = useState(null)

  const Store = {
    choiceQ1,
    choiceQ2,
    choiceQ3,
    choiceQ4,
    choiceQ5,
    choiceQ6,
    choiceQ7,
    choiceQ8,
    choiceQ9,
    choiceQ10,
    choiceQ11,
    choiceQ12,
    choiceQ13,
    choiceQ14,
    choiceQ17,
    choiceQ18,
  };
  const [path, setPath] = useState([]);
  const [isAddressFound, setIsAddressFound] = useState(true);
  const stepText = TEXT_IMAGE.find((step) => {
    return (
      step.step === questionCounter &&
      (step.isAddressNotFound
        ? step.isAddressNotFound !== isAddressFound
        : true)
    );
  });

  useEffect(() => {
    if (center)
      setPath([
        { lat: Number(center.lat) + 0.0001, lng: Number(center.lng) + 0.0001 },
        { lat: Number(center.lat) + 0.0001, lng: Number(center.lng) - 0.0001 },
        { lat: Number(center.lat) - 0.0001, lng: Number(center.lng) - 0.0001 },
        { lat: Number(center.lat) - 0.0001, lng: Number(center.lng) + 0.0001 },
      ]);
  }, [center]);

  const handleSubmit = () => {
    const data = {
      choiceQ1,
      choiceQ2,
      choiceQ3,
      choiceQ4,
      choiceQ5,
      choiceQ6,
      choiceQ7,
      choiceQ8,
      choiceQ9,
      choiceQ10,
      choiceQ11,
      choiceQ12,
      choiceQ13,
      choiceQ14,
      choiceQ17: {
        ...choiceQ17,
        code_parrainage: context.code,
      },
      choiceQ18,
      offre: promoOffre,
    };
    fetch(BACKEN_URL + "/generate-pdf", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(() => {
        setQuestionCounter((c) => c + 1);
      })
      .catch((err) => {
        alert("Une erreur est survenue");
      });
  };

  const handleChangeContainer = (newContainer) => {
    setContainerCounter(newContainer);
  };
  return context.store.loading ? (
    <div
      className="App"
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header />
      <Header2 />
      <Paper style={{ flex: 1, overflow: "hidden" }}>
        <Grid container spacing={3} style={{ height: "100%", width: "100%" }}>
          {showPicture && (
            <Grid item xs={4} style={{ height: "100%", position: "relative" }}>
              <img
                width={"100%"}
                height={"100%"}
                style={{ objectFit: "cover", marginLeft: "1rem" }}
                src={
                  [1, 2, 3, 4, 5, 6, 7, 8, 9].includes(questionCounter)
                    ? panneau
                    : [10, 11, 12, 13, 14].includes(questionCounter)
                      ? consommation
                      : projet
                }
                alt="energie solaire"
              />
              {stepText && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                    left: "3rem",
                    display: "flex",
                  }}
                >
                  <Card sx={{ backgroundColor: "rgba(255,255,255,0.85)" }}>
                    <CardContent>
                      <Typography variant="p" component="p">
                        {stepText.text}
                      </Typography>
                    </CardContent>
                  </Card>
                  <img
                    src={logoSM}
                    alt="logo ASE"
                    style={{
                      borderRadius: "5px",
                      padding: "1px",
                      backgroundColor: "white",
                      objectFit: "cover",
                      width: "2.5rem",
                      height: "2.5rem",
                      marginLeft: "0.5rem",
                    }}
                  />
                </Box>
              )}
            </Grid>
          )}
        </Grid>
      </Paper>
      <Footer />
    </div>
  ) : (
    <div
      className="App"
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header />
      <Header2 />

      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          if (newValue === 0) {
            setContainerCounter(1);
            setQuestionCounter(1);
          }
          if (newValue === 1) {
            setContainerCounter(2);
            setQuestionCounter(10);
          }
          if (newValue === 2) {
            setContainerCounter(3);
            setQuestionCounter(15);
          }
        }}
        //  style={{ position: "fixed", bottom: 0, width: "100%" }}
        style={{ padding: "1rem 0" }}
        color={"primary"}
      >
        <BottomNavigationAction
          label="Mon logement"
          icon={<HomeTwoToneIcon fontSize="large" />}
          color={"primary"}
          // set pointer events to none to disable click
          style={{ cursor: "pointer" }}
        />
        <BottomNavigationAction
          label="Ma consommation"
          icon={<PowerTwoToneIcon fontSize="large" />}
          disabled={!visitedConsommation}
          color={"primary"}
          style={{
            cursor: !visitedConsommation ? "not-allowed" : "pointer",
          }}
        />
        <BottomNavigationAction
          label="Mon projet"
          icon={<InventoryTwoToneIcon fontSize="large" />}
          disabled={!visitedProjet}
          color={"primary"}
          style={{ cursor: !visitedProjet ? "not-allowed" : "pointer" }}
        />
      </BottomNavigation>

      <Paper style={{ flex: 1, overflow: "hidden" }}>
        <Grid container spacing={3} style={{ height: "100%", width: "100%" }}>
          {showPicture && (
            <Grid item xs={4} style={{ height: "100%", position: "relative" }}>
              <img
                width={"100%"}
                height={"100%"}
                style={{ objectFit: "cover", marginLeft: "1rem" }}
                src={
                  [1, 2, 3, 4, 5, 6, 7, 8, 9].includes(questionCounter)
                    ? panneau
                    : [10, 11, 12, 13, 14].includes(questionCounter)
                      ? consommation
                      : projet
                }
                alt="energie solaire"
              />
              {stepText && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                    left: "3rem",
                    display: "flex",
                  }}
                >
                  <Card sx={{ backgroundColor: "rgba(255,255,255,0.85)" }}>
                    <CardContent>
                      <Typography variant="p" component="p">
                        {stepText.text}
                      </Typography>
                    </CardContent>
                  </Card>
                  <img
                    src={logoSM}
                    alt="logo ASE"
                    style={{
                      borderRadius: "5px",
                      padding: "1px",
                      backgroundColor: "white",
                      objectFit: "cover",
                      width: "2.5rem",
                      height: "2.5rem",
                      marginLeft: "0.5rem",
                    }}
                  />
                </Box>
              )}
            </Grid>
          )}

          <Grid
            item
            xs={showPicture ? 8 : 12}
            sx={{
              padding: "1rem",
              overflow: "auto",
              height: "100%",
              width: "100%",
            }}
          >
            {containerCounter === 1 && (
              <ContainerLogement
                questionCounter={questionCounter}
                choiceQ1={choiceQ1}
                setChoiceQ1={setChoiceQ1}
                choiceQ2={choiceQ2}
                setChoiceQ2={setChoiceQ2}
                choiceQ3={choiceQ3}
                setChoiceQ3={setChoiceQ3}
                choiceQ4={choiceQ4}
                setChoiceQ4={setChoiceQ4}
                choiceQ5={choiceQ5}
                setChoiceQ5={setChoiceQ5}
                choiceQ6={choiceQ6}
                setChoiceQ6={setChoiceQ6}
                choiceQ7={choiceQ7}
                setChoiceQ7={setChoiceQ7}
                choiceQ8={choiceQ8}
                setChoiceQ8={setChoiceQ8}
                choiceQ9={choiceQ9}
                setChoiceQ9={setChoiceQ9}
                isAddressFound={isAddressFound}
                handleClickNext={handleClickNext}
                handleClickPrevious={handleClickPrevious}
                setIsAddressFound={setIsAddressFound}
                center={center}
                setCenter={setCenter}
                path={path}
                setPath={setPath}
                setQuestionToValue={setQuestionToValue}
                handleChangeContainer={handleChangeContainer}
                handleVisitConsommation={handleVisitConsommation}
              />
            )}
            {containerCounter === 2 && (
              <ContainerConsommation
                questionCounter={questionCounter}
                choiceQ10={choiceQ10}
                setChoiceQ10={setChoiceQ10}
                choiceQ11={choiceQ11}
                setChoiceQ11={setChoiceQ11}
                choiceQ12={choiceQ12}
                setChoiceQ12={setChoiceQ12}
                choiceQ13={choiceQ13}
                setChoiceQ13={setChoiceQ13}
                choiceQ14={choiceQ14}
                setChoiceQ14={setChoiceQ14}
                handleClickNext={handleClickNext}
                handleClickPrevious={handleClickPrevious}
                setQuestionToValue={setQuestionToValue}
                choiceQ7={choiceQ7}
                choiceQ9={choiceQ9}
                handleChangeContainer={handleChangeContainer}
                handleVisitProjet={handleVisitProjet}
                surface={choiceQ5}
              />
            )}
            {containerCounter === 3 && (
              <ContainerProjet
                Store={Store}
                isFirst={false}
                isLast={false}
                next={handleClickNext}
                previous={handleClickPrevious}
                choiceQ14={choiceQ14}
                setChoiceQ14={setChoiceQ14}
                choiceQ17={choiceQ17}
                setChoiceQ17={setChoiceQ17}
                choiceQ18={choiceQ18}
                setChoiceQ18={setChoiceQ18}
                handleSubmit={handleSubmit}
                questionCounter={questionCounter}
                setQuestionToValue={setQuestionToValue}
                handleChangeContainer={handleChangeContainer}
                setPromoOffre={setPromoOffre}
              />
            )}
            {containerCounter === 4 && <Question19 />}
          </Grid>
        </Grid>
      </Paper>
      <Footer />
    </div>
  );
};
