import { useContext } from "react";
import { GlobalContext } from "../Context/GloablContext";
import { getNbrPvFromArea } from "./CalculSheet";
import {
  COEFICIENT_TOIT_MAISON,
  COEF_ORIENTATION,
  DONT_TAX,
  PRIX_KWH,
  PUISSANCE_PANEAUX,
  TYPE_ONDULEUR,
  TYPE_PANEAUX,
} from "./CONSOMMATION";

function useOffre({ Store }) {
  const context = useContext(GlobalContext);
  const dataSolarPanel = getDataSolarPanels(Store, context);

  const prime = getPrime(dataSolarPanel.production_kwh);

  const prixFinal = dataSolarPanel.prix - prime;
  const retourSurInvestissement = getRetourSurInvestissement(
    dataSolarPanel.solarGain,
    prixFinal
  );
  const valorisationYear1 = Math.round(
    getValorisation(dataSolarPanel.solarGain, 1)
  );
  const valorisationYear10 = Math.round(
    getValorisation(dataSolarPanel.solarGain, 10)
  );
  const valorisationYear20 = Math.round(
    getValorisation(dataSolarPanel.solarGain, 20)
  );

  return {
    estimationBaisseFacture:
      (dataSolarPanel.solarGain / dataSolarPanel.facture) * 100,
    prixPanels: dataSolarPanel.prix,
    retourSurInvestissement,
    nbrePaneaux: dataSolarPanel.nbrPv,
    puissanceCentrale: dataSolarPanel.production_kwh,
    puissancePaneaux: PUISSANCE_PANEAUX,
    productionPaneauxParAns: Math.round(dataSolarPanel.production_reel),
    panelType: TYPE_PANEAUX,
    onduleurType: TYPE_ONDULEUR,
    prime: prime,
    prixFinal,
    dontTax: DONT_TAX,
    valorisationYear1,
    valorisationYear20,
    valorisationYear10,
  };
}

export default useOffre;

const getDataSolarPanels = (Store, context) => {
  const facture = Store.choiceQ14.facture;
  const { production_reel, ...rest } = getProductionPaneau(Store, context);
  const solarGain = production_reel * PRIX_KWH;

  return {
    production_reel,
    ...rest,
    solarGain,
    facture,
  };
};

const getProductionPaneau = (Store, context) => {
  const surfaceMaison = Store.choiceQ5;
  const areaInput = Store.choiceQ9;
  const heureEnso = Store.choiceQ6?.heure ? Store.choiceQ6.heure : 1;
  const orientation = Store.choiceQ8;
  const coefOrientation = COEF_ORIENTATION[orientation]
    ? COEF_ORIENTATION[orientation]
    : 1;
  let area = 0;
  if (areaInput === "Moyenne 20-40m²") {
    area = surfaceMaison * COEFICIENT_TOIT_MAISON;
  } else if (areaInput === "Petite 15-20m²") {
    area = surfaceMaison * COEFICIENT_TOIT_MAISON;
  } else if (areaInput === "Grande > 40m²") {
    area = surfaceMaison * COEFICIENT_TOIT_MAISON;
  } else {
    area = areaInput;
  }
  const productionToutType = getProductionToutType(
    heureEnso,
    coefOrientation,
    context
  );
  //here
  const PV = getNbrPvFromArea(
    area,
    Store.choiceQ14.consommation,
    productionToutType,
    context
  );
  return PV;
};

const getRetourSurInvestissement = (solarGain, prixFinal) => {
  let solarGainYear = solarGain;
  let prix = prixFinal;
  let years = 0;
  while (prix > 0) {
    prix = prix - solarGainYear;
    solarGainYear = solarGainYear * 1.07;
    years++;
  }
  return Math.floor(years);
};

const getValorisation = (solarGain, years) => {
  let val = 0;
  let solarGainYear = solarGain;
  for (let year = 1; year <= years; year++) {
    val += solarGainYear;
    solarGainYear = solarGainYear * 1.07;
    if (year >= 9) solarGainYear = solarGainYear * 0.97;
  }
  return val;
};

const getPrime = (production) => {
  if (production === 3) return production * 510;//430
  else return production * 380;//320
};

const getProductionToutType = (heureEnso, coefOrientation, context) => {
  const { store } = context;
  const el = store.offres.map((item) => ({
    ...item,
    prod: heureEnso * item.nbPanneaux * 0.375 * 1.15 * 0.84 * coefOrientation,
  }));
  return el;
};

export function useOffrePromo({ Store, offre, remise }) {
  const context = useContext(GlobalContext);

  if (offre) {
    const dataSolarPanel = getDataSolarPanelsPromo(Store, context, offre);

    const prime = getPrime(dataSolarPanel.production_kwh);

    const prixFinal = dataSolarPanel.prix - prime;
    const retourSurInvestissement = getRetourSurInvestissement(
      dataSolarPanel.solarGain,
      prixFinal
    );
    const valorisationYear1 = Math.round(
      getValorisation(dataSolarPanel.solarGain, 1)
    );
    const valorisationYear10 = Math.round(
      getValorisation(dataSolarPanel.solarGain, 10)
    );
    const valorisationYear20 = Math.round(
      getValorisation(dataSolarPanel.solarGain, 20)
    );
    console.log("remise", remise)
    return {
      estimationBaisseFacture:
        (dataSolarPanel.solarGain / dataSolarPanel.facture) * 100,
      prixPanels: dataSolarPanel.prix,
      retourSurInvestissement,
      nbrePaneaux: dataSolarPanel.nbrPv,
      puissanceCentrale: dataSolarPanel.production_kwh,
      puissancePaneaux: PUISSANCE_PANEAUX,
      productionPaneauxParAns: Math.round(dataSolarPanel.production_reel),
      panelType: TYPE_PANEAUX,
      onduleurType: TYPE_ONDULEUR,
      prime: prime,
      prixFinal,
      dontTax: DONT_TAX,
      valorisationYear1,
      valorisationYear20,
      valorisationYear10,
      remise: remise
    };
  } else {
    return null;
  }
}

const getDataSolarPanelsPromo = (Store, context, offre) => {
  const facture = Store.choiceQ14.facture;
  const { production_reel, ...rest } = getProductionPaneauPromo(
    Store,
    context,
    offre
  );
  const solarGain = production_reel * PRIX_KWH;

  return {
    production_reel,
    ...rest,
    solarGain,
    facture,
  };
};
const getProductionPaneauPromo = (Store, context, offre) => {
  const surfaceMaison = Store.choiceQ5;
  const areaInput = Store.choiceQ9;
  const heureEnso = Store.choiceQ6?.heure ? Store.choiceQ6.heure : 1;
  const orientation = Store.choiceQ8;
  const coefOrientation = COEF_ORIENTATION[orientation]
    ? COEF_ORIENTATION[orientation]
    : 1;
  let area = 0;
  if (areaInput === "Moyenne 20-40m²") {
    area = surfaceMaison * COEFICIENT_TOIT_MAISON;
  } else if (areaInput === "Petite 15-20m²") {
    area = surfaceMaison * COEFICIENT_TOIT_MAISON;
  } else if (areaInput === "Grande > 40m²") {
    area = surfaceMaison * COEFICIENT_TOIT_MAISON;
  } else {
    area = areaInput;
  }
  const productionToutType = getProductionToutType(
    heureEnso,
    coefOrientation,
    context
  );

  const selectedOffre = productionToutType.filter(
    (item) => item._id === offre._id
  )[0];
  return {
    nbrPv: selectedOffre.nbPanneaux,
    prix: selectedOffre.prixTTC,
    production_kwh: selectedOffre.puissance,
    production_reel: selectedOffre.prod,
  };
};
