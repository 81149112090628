import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import CustomElectromenagerInputs from "../CustomInputs/CustomElectromenagerInputs";

function Question13({
  isFirst,
  isLast,
  next,
  previous,
  choice,
  setChoice,
  isSelected,
  setQuestionToValue,
}) {
  const handleChangeRefrigerateurValue = (event) => {
    setChoice({
      ...choice,
      Refrigerateur: event,
    });
  };
  const handleChangeLaveLingeValue = (event) => {
    setChoice({
      ...choice,
      LaveLinge: event,
    });
  };
  const handleChangeLaveVaisselleValue = (event) => {
    setChoice({
      ...choice,
      LaveVaisselle: event,
    });
  };
  const handleChangeCongelateurValue = (event) => {
    setChoice({
      ...choice,
      Congelateur: event,
    });
  };
  const handleChangeSecheLingesValue = (event) => {
    setChoice({
      ...choice,
      SecheLinges: event,
    });
  };
  const handleChangePiscineValue = (event) => {
    setChoice({
      ...choice,
      Piscine: event,
    });
  };
  const handleChangePompePiscineValue = (event) => {
    setChoice({
      ...choice,
      PompePiscine: event,
    });
  };
  const handleChangevoitureElectriqueValue = (event) => {
    setChoice({
      ...choice,
      voitureElectrique: event,
    });
  };
  const cardRef = React.useRef(null);

  useEffect(() => {
    if (isSelected) {
      cardRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [isSelected]);
  return (
    <Card
      sx={{ padding: "1rem" }}
      variant="outlined"
      ref={cardRef}
      className={isSelected ? "ae-card selected" : "ae-card"}
      onClick={() => setQuestionToValue(13)}
    >
      <CardHeader
        title="Quels sont vos équipements électroménagers ?"
        // subheader="Vos appareils énergivores ont un impact important sur votre facture et les économies que vous pourriez réaliser."
      />
      <CardContent
        sx={{
          width: "100%",
        }}
      >
        <FormControl component="fieldset" variant="standard">
          <FormGroup style={{ display: "flex" }}>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <Typography variant="body1" gutterBottom>
                  Réfrigérateur :
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <CustomElectromenagerInputs
                  value={
                    choice.Refrigerateur === 0 ? "Aucun" : choice.Refrigerateur
                  }
                  setValue={handleChangeRefrigerateurValue}
                />
              </Grid>
              <Grid item xs={5}>
                <Typography variant="body1" gutterBottom>
                  Congélateur :
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <CustomElectromenagerInputs
                  value={
                    choice.Congelateur === 0 ? "Aucun" : choice.Congelateur
                  }
                  setValue={handleChangeCongelateurValue}
                />
              </Grid>
              <Grid item xs={5}>
                <Typography variant="body1" gutterBottom>
                  Lave vaisselle :
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <CustomElectromenagerInputs
                  value={
                    choice.LaveVaisselle === 0 ? "Aucun" : choice.LaveVaisselle
                  }
                  setValue={handleChangeLaveVaisselleValue}
                />
              </Grid>
              <Grid item xs={5}>
                <Typography variant="body1" gutterBottom>
                  Lave linge :
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <CustomElectromenagerInputs
                  value={choice.LaveLinge === 0 ? "Aucun" : choice.LaveLinge}
                  setValue={handleChangeLaveLingeValue}
                />
              </Grid>
              <Grid item xs={5}>
                <Typography variant="body1" gutterBottom>
                  Sèche linge :
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <CustomElectromenagerInputs
                  value={
                    choice.SecheLinges === 0 ? "Aucun" : choice.SecheLinges
                  }
                  setValue={handleChangeSecheLingesValue}
                />
              </Grid>
              <Grid item xs={5}>
                <Typography variant="body1" gutterBottom>
                  Piscine :
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <CustomElectromenagerInputs
                  value={choice.Piscine === 0 ? "Aucun" : choice.Piscine}
                  setValue={handleChangePiscineValue}
                />
              </Grid>

              <Grid item xs={5}>
                <Typography variant="body1" gutterBottom>
                  Pompe à chaleur piscine :
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <CustomElectromenagerInputs
                  value={
                    choice.PompePiscine === 0 ? "Aucun" : choice.PompePiscine
                  }
                  setValue={handleChangePompePiscineValue}
                />
              </Grid>

              <Grid item xs={5}>
                <Typography variant="body1" gutterBottom>
                  Voiture électrique(220 km/semaine) :
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <CustomElectromenagerInputs
                  value={
                    choice.voitureElectrique === 0
                      ? "Aucun"
                      : choice.voitureElectrique
                  }
                  setValue={handleChangevoitureElectriqueValue}
                />
              </Grid>
            </Grid>
          </FormGroup>
        </FormControl>
      </CardContent>
      <CardActions
        style={{
          display: "flex",
          justifyContent: "end",
          padding: "1em",
        }}
      >
        <Button
          style={{ textAlign: "end" }}
          variant="contained"
          disabled={isLast}
          onClick={(e) => {
            e.stopPropagation();
            next(0);
          }}
        >
          Suivant
          <NavigateNextIcon color="black" />
        </Button>
      </CardActions>
    </Card>
  );
}

export default Question13;
